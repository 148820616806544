<template>
  <div class="customer-item headline">
  <div class="customerLineWrap">
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-company">
      <span @click="emitSort('c_company')"  :class="(sortDirections.c_company) ? 'active' : ''">Unternehmen</span>
      <i v-if="sortDirections.c_company === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.c_company === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-number" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_customernr_listing === '1'" >
      <span @click="emitSort('c_customer_id')"  :class="(sortDirections.c_customer_id) ? 'active' : ''">Kundennr.</span>
      <i v-if="sortDirections.c_customer_id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.c_customer_id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-prio" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_prio_listing === '1'">
      <span tooltip="Priorität" position="left" @click="emitSort('_prio_name')" :class="(sortDirections._prio_name) ? 'active' : ''"><i class="fa-duotone fa-star"></i></span>
      <i v-if="sortDirections._prio_name === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections._prio_name === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-akquise" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_akquise_listing === '1'">
      <span tooltip="Akquise-Stand" :position="'left'" @click="emitSort('_akquise_name')" :class="(sortDirections._akquise_name) ? 'active' : ''"><i class="fa-duotone fa-timeline"></i></span>
      <i v-if="sortDirections._akquise_name === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections._akquise_name === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-type" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_custype_listing === '1'">
      <span @click="emitSort('_type_name')" :class="(sortDirections._type_name) ? 'active' : ''">Typ</span>
      <i v-if="sortDirections._type_name === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections._type_name === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-birthday" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_birthday_listing === '1'">
      <span @click="emitSort('c_birthday')" :class="(sortDirections.c_birthday) ? 'active' : ''">Geburtstag</span>
      <i v-if="sortDirections.c_birthday === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.c_birthday === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-verband license" v-if="getPlugins.verband">
      <span>Lizenz</span>
    </div>
    <div class="lineitem l-verband" v-if="getPlugins.verband">
      <span tooltip="Lerneinheiten">LE</span>
    </div>

    <Holzkraft mode="listSort"  @sort="handleEmitSort" :sort-directions="sortDirections" v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft"  />
    <Dfb mode="listSort" @sort="handleEmitSort" :sort-directions="sortDirections" v-if="getPlugins  && getPlugins.infos && getPlugins.infos.trainer"  />
    <div class="lineitem l-country" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_country_listing === '1'">
      <span>Land</span>
    </div>
    <div class="lineitem l-createdon" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_createdon_listing === '1'">
      <span @click="emitSort('ts_created')" :class="(sortDirections.ts_created) ? 'active' : ''" tooltip="Erstellungsdatum" :position="'left'">Datum</span>
      <i v-if="sortDirections.ts_created === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.ts_created === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-last-activity" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_last_activity_listing === '1'">
      <span @click="emitSort('c_last_activity')" :class="(sortDirections.c_last_activity) ? 'active' : ''" tooltip="Letzte Aktivität" :position="'left'" >Aktivität</span>
      <i v-if="sortDirections.c_last_activity === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.c_last_activity === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem actions" >

    </div>
    <div class="lineitem l-actions ">
      </div>
    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";
import Dfb from "@/routing/plugins/dfb/Customer.vue";

export default {
  name: "Sort",
  components: {Holzkraft,Dfb},
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        id: '',
        c_company:'',
        c_customer_id:'',
        c_last_activity:'',
        _prio_name:'',
        _akquise_name:'',
        c_owner:'',
        c_birthday:'',
        ts_created:'',
        _type_name:'',
        kwel: '',
        preisProKw: '',
        projektGesamtkosten: '',
        anzahlAnlagen: '',
      }

    }
  },
  props: {
    customer: Object,
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "getConfigs", "getPlugins"])
  },
  methods: {
    handleEmitSort(sort) {
      this.emitSort(sort.type)

    },
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
