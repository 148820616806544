<template>
  <div class="appView config orangeView">
    <div class="productItem activeProduct">
      <div class="card">
        <h6 class="card-header ">{{ $t("globalSetting.globalSettings") }}
          <div class="btn-group float-end">
            <button @click="$router.push('/config/basic')" class="btn btn-sm btn-outline-secondary  ml-auto"><i
              class="fa-duotone fa-x"></i> Abbrechen
            </button>
            <button @click="saveConfig"
                    type="button" class=" btn btn-sm btn-outline-primary">
              <i class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
            </button>
          </div>
        </h6>
        <ul class="tabNav">
          <li v-for="cat in configTabs" :key="cat.name"
              @click="changeTab(cat.name)"
              :class="(activeSubtab === cat.name) ? 'active' : ''"><i class="fa-duotone"
                                                                      :class="cat.icon"></i><br><small>{{
              cat.label
            }}</small>
          </li>
        </ul>
        <div class="card-body" v-if="activeSubtab === 'general'">
          <h6>{{ $t("globalSetting.companyInfo") }}</h6>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="company_name">{{ $t("globalSetting.companyName1") }}</label>
                <input id="company_name" v-model="config.company_name" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="company_name2">{{ $t("globalSetting.companyName2") }}</label>
                <input id="company_name2" v-model="config.company_name2" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="company_name2">{{ $t("globalSetting.companyOwner") }}</label>
                <input id="company_name2" v-model="config.company_ceo" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-5">
              <div class="form-group">
                <label for="company_street">{{ $t("globals.street") }}</label>
                <input id="company_street" v-model="config.company_street" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="company_zip">{{ $t("globals.zipCode") }}</label>
                <input id="company_zip" v-model="config.company_zip" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label for="company_city">{{ $t("globals.city") }}</label>
                <input id="company_city" v-model="config.company_city" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_phone">{{ $t("globals.phone") }}</label>
                <input id="company_phone" v-model="config.company_phone" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_fax">{{ $t("globals.faxMachine") }}</label>
                <input id="company_fax" v-model="config.company_fax" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_email">{{ $t("globals.email") }}</label>
                <input id="company_email" v-model="config.company_email" type="email" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_website">{{ $t("globals.website") }}</label>
                <input id="company_website" v-model="config.company_website" type="url" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_iban">{{ $t("bankAccounts.details.iban") }}</label>
                <input id="company_iban" v-model="config.company_iban" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_bic">{{ $t("bankAccounts.details.bic") }}</label>
                <input id="company_bic" v-model="config.company_bic" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_bankname">{{ $t("globals.bankname") }}</label>
                <input id="company_bankname" v-model="config.company_bankname" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_ustid">{{ $t("globals.ustid") }}</label>
                <input id="company_ustid" v-model="config.company_ustid" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_hrb">{{ $t("globals.hrb") }}</label>
                <input id="company_hrb" v-model="config.company_hrb" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="company_court">{{ $t("globals.court") }}</label>
                <input id="company_court" v-model="config.company_court" type="text" class="form-control"/>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <!-- Other fields here -->
            <div class="col-sm">
              <h6>{{ $t("globalSetting.noteSettings") }}</h6>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="n_categories">{{ $t("globalSetting.noteCategories") }}</label>
                <textarea id="n_categories" v-model="config.n_categories" class="form-control"></textarea>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="customer_group_discount">{{ $t("globalSetting.groupDiscount") }}</label>
                <textarea id="customer_group_discount" v-model="config.customer_group_discount"
                          class="form-control"></textarea>
              </div>
            </div>
            <div class="col-12">
              <h6>{{ $t("globalSetting.accessDataSettings") }}</h6>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="a_categories">{{ $t("globalSetting.accessDataCategories") }}</label>
                <textarea id="a_categories" v-model="config.a_categories" class="form-control"></textarea>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="additional_legals">{{ $t("globalSetting.additionalLegals") }}</label>
                <textarea id="additional_legals" v-model="config.additional_legals" class="form-control"></textarea>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Custom CSS</label>
                <template v-if="config.vue_css === undefined">
                  <textarea v-model="config.vue_css" cols="30" rows="10"></textarea>
                </template>
                <template v-else>
                  <EditorHtml v-model="config.vue_css" :languages="[['css','CSS']]"/>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="activeSubtab === 'numbers'">
          <h6>{{ $t("globalSetting.numberRange.numberRanges") }}</h6>
          <div class="row">
            <div class="col-sm">
              <div class="form-group">
                <label for="num_customer">{{ $t("globalSetting.numberRange.customerNumber") }}</label>
                <input id="num_customer" v-model="config.num_customer" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="num_angebot">{{ $t("globalSetting.numberRange.offers") }}</label>
                <input id="num_angebot" v-model="config.num_angebot" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="num_auftrag">{{ $t("globalSetting.numberRange.orderConfirmation") }}</label>
                <input id="num_auftrag" v-model="config.num_auftrag" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="num_rechnung">{{ $t("globalSetting.numberRange.bills") }}</label>
                <input id="num_rechnung" v-model="config.num_rechnung" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="num_abschlag">{{ $t("globalSetting.numberRange.partialInvoices") }}</label>
                <input id="num_abschlag" v-model="config.num_abschlag" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="num_abschluss">{{ $t("globalSetting.numberRange.finalInvoices") }}</label>
                <input id="num_abschluss" v-model="config.num_abschluss" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="num_gutschrift">{{ $t("globalSetting.numberRange.credits") }}</label>
                <input id="num_gutschrift" v-model="config.num_gutschrift" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="num_lieferschein">{{ $t("globalSetting.numberRange.deliveryNote") }}</label>
                <input id="num_lieferschein" v-model="config.num_lieferschein" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="num_website">{{ $t("globalSetting.numberRange.websiteInvoices") }}</label>
                <input id="num_website" v-model="config.num_website" type="text" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>{{ $t("globalSetting.numberRange.separateNumberRanges") }}</label>
                <textarea v-model="config.seperateCustomerNumbers" class="form-control"></textarea></div>
            </div>
          </div>
          <h6></h6>
          <div class="row">
            <div class="col-sm">
              <div class="form-group">
                <label for="contract_repeating_day">{{ $t("globalSetting.numberRange.contractRepDay") }}</label>
                <input id="contract_repeating_day" v-model="config.contract_repeating_day" type="number"
                       class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="contract_repeating_month">{{ $t("globalSetting.numberRange.contractRepMonth") }}</label>
                <input id="contract_repeating_month" v-model="config.contract_repeating_month" type="number"
                       class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="defaultAccountNumber">{{ $t("globalSetting.numberRange.defaultAccountNumber") }}</label>
                <input id="defaultAccountNumber" v-model="config.defaultAccountNumber" type="text"
                       class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="contract_repeating_month">Standard-Konto für Buchhaltung</label>
                <input id="contract_repeating_month" v-model="config.accountingDefault" type="text"
                       class="form-control"/>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label for="contract_repeating_month">Standard-Länge für Buchungskonten</label>
                <input id="contract_repeating_month" v-model="config.accountingLength" type="text"
                       class="form-control"/>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="activeSubtab === 'emails'">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.emails.separatesMailing") }}</label>
                <div class="btn-group me-3 ms-3">
                  <input type="radio" value="0" class="btn-check" id="use_crm_mailing-no" autocomplete="off"
                         v-model.number="config.use_crm_mailing">
                  <label class="btn btn-outline-primary" for="use_crm_mailing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_crm_mailing-yes" autocomplete="off"
                         v-model.number="config.use_crm_mailing">
                  <label class="btn btn-outline-primary" for="use_crm_mailing-yes">{{ $t("globals.yes") }}</label>
                </div>
              </div>
            </div>
            <div class="col-12" v-if="config.use_crm_mailing === '1'">
              <div class="form-group">
                <label for="mailfrom">{{ $t("globalSetting.emails.mailform") }}</label>
                <input id="mailfrom" v-model="config.mailfrom" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12" v-if="config.use_crm_mailing === '1'">
              <div class="form-group">
                <label for="fromname">{{ $t("globalSetting.emails.formname") }}</label>
                <input id="fromname" v-model="config.fromname" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12" v-if="config.use_crm_mailing === '1'">
              <div class="form-group">
                <label for="smtphost">{{ $t("globalSetting.emails.smtphost") }}</label>
                <input id="smtphost" v-model="config.smtphost" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12" v-if="config.use_crm_mailing === '1'">
              <div class="form-group">
                <label for="smtpport">{{ $t("globalSetting.emails.smtpport") }}</label>
                <input id="smtpport" v-model="config.smtpport" type="text" class="form-control"/>
              </div>
            </div>
            <!-- And so on until 'email_signature' -->
            <div class="col-12">
              <div class="form-group">
                <label for="email_logo">{{ $t("globalSetting.emails.emailLogo") }}</label>
                <input id="email_logo" v-model="config.email_logo" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="email_signature">{{ $t("globalSetting.emails.emailSignature") }}</label>
                <Editor2 id="email_signature" v-model="config.email_signature" class="form-control"></Editor2>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>E-Mail Template</label>
                <EditorHtml v-model="config.email_template" :languages="[['html','HTML'],['css','CSS']]"/>
              </div>
            </div>
            <div class="col-12">
              <h5>E-Mail-Archiv</h5>
              <div class="control-group">
                <div class="control-label">
                  <label id="jform_ticket_email_server-lbl" for="jform_ticket_email_server">
                    Server</label>
                </div>
                <div class="controls">
                  <input type="text" class="form-control" v-model="config.archive_email_server" id="jform_ticket_email_server">
                </div>
              </div>
              <div class="control-group">
                <div class="control-label">
                  <label id="jform_ticket_email_port-lbl" for="jform_ticket_email_port">
                    Port</label>
                </div>
                <div class="controls">
                  <input type="text" class="form-control" v-model="config.archive_email_port" id="jform_ticket_email_port">
                </div>
              </div>
              <div class="control-group">
                <div class="control-label">
                  <label id="jform_ticket_email_user-lbl" for="jform_ticket_email_user">
                    Benutzername</label>
                </div>
                <div class="controls">
                  <input type="text" class="form-control" v-model="config.archive_email_user" id="jform_ticket_email_user" >
                </div>
              </div>
              <div class="control-group">
                <div class="control-label">
                  <label id="jform_ticket_email_pass-lbl" for="jform_ticket_email_pass">
                    Passwort</label>
                </div>
                <div class="controls">
                  <input type="text" class="form-control" v-model="config.archive_email_pass" id="jform_ticket_email_pass" >
                </div>
              </div>
              <div class="control-group">
                <div class="control-label">
                  <label id="jform_filter_ticket_files-lbl" for="jform_filter_ticket_files" class="hasPopover" title="" data-content="Komma separtiert die Dateinamen mit Dateiendung eingeben." data-original-title="Filter Dateien">
                    Filter Dateien</label>
                </div>
                <div class="controls">
                  <textarea class="form-control" v-model="config.filter_archive_files" id="jform_filter_ticket_files"></textarea>									</div>
              </div>
              <div class="control-group">
                <div class="control-label">
                  <label id="jform_filter_ticket_mimetypes-lbl" for="jform_filter_ticket_mimetypes" class="hasPopover" title="" data-content="Komma separtiert die Dateinamen mit Dateiendung eingeben." data-original-title="Filter Mime-types">
                    Filter Mime-types</label>
                </div>
                <div class="controls">
                  <textarea class="form-control" v-model="config.filter_archive_mimetypes" id="jform_filter_ticket_mimetypes"></textarea>									</div>
              </div>
            </div>

          </div>
        </div>
        <div class="card-body" v-if="activeSubtab === 'document'">
          <div class="row">
            <!-- use_deleted_numbers_again field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="no" class="btn-check" id="use_deleted_numbers_again-no" autocomplete="off"
                         v-model="config.use_deleted_numbers_again">
                  <label class="btn btn-outline-primary" for="use_deleted_numbers_again-no">{{
                      $t("globals.no")
                    }}</label>
                  <input type="radio" value="yes" class="btn-check" id="use_deleted_numbers_again-yes"
                         autocomplete="off" v-model="config.use_deleted_numbers_again">
                  <label class="btn btn-outline-primary" for="use_deleted_numbers_again-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>Gelöschte Dokumenten-Nummern erneut vergeben?</label>
              </div>
            </div>
            <!-- payment_due_date field -->
            <div class="col-12">
              <div class="form-group">
                <label for="payment_due_date">Zahlungsziel in Tagen</label>
                <input id="payment_due_date" v-model="config.payment_due_date" type="number" class="form-control"/>
              </div>
            </div>
            <!-- use_ean_in_document field -->
            <div class="col-12">
              <div class="form-group">
                <label>EAN-Nummern in PDF nutzen</label>
                <select id="use_ean_in_document" v-model.number="config.use_ean_in_document" class="form-select">
                  <option value="0">Nein</option>
                  <option value="1">Ja</option>
                  <option value="2">Product EAN ist Produkt-Nummer</option>
                </select>
              </div>
            </div>
            <!-- use_delivery_state field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_delivery_state-no" autocomplete="off"
                         v-model.number="config.use_delivery_state">
                  <label class="btn btn-outline-primary" for="use_delivery_state-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_delivery_state-yes" autocomplete="off"
                         v-model.number="config.use_delivery_state">
                  <label class="btn btn-outline-primary" for="use_delivery_state-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>Liefer-Status in Rechnungen</label>
              </div>
            </div>
            <!-- use_info_from_shop field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_info_from_shop-no" autocomplete="off"
                         v-model.number="config.use_info_from_shop">
                  <label class="btn btn-outline-primary" for="use_info_from_shop-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_info_from_shop-yes" autocomplete="off"
                         v-model.number="config.use_info_from_shop">
                  <label class="btn btn-outline-primary" for="use_info_from_shop-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>Shopinfo bei Aufträge</label>
              </div>
            </div>
            <!-- accounting_main_cfg field -->
            <div class="col-12">
              <div class="form-group">
                <label>Art der Buchhaltung</label>
                <select id="accounting_main_cfg" v-model="config.accounting_main_cfg" class="form-select">
                  <option value="">Bilanzbuchhaltung</option>
                  <option value="eur">Einnahme-Überschuss-Rechnung</option>
                </select>
              </div>
            </div>
            <!-- document_sending field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="" class="btn-check" id="document_sending-no" autocomplete="off"
                         v-model.number="config.document_sending">
                  <label class="btn btn-outline-primary" for="document_sending-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="document_sending-yes" autocomplete="off"
                         v-model.number="config.document_sending">
                  <label class="btn btn-outline-primary" for="document_sending-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>Automatischer Versand?</label>
              </div>
            </div>
            <!-- extend_doc_info field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="extend_doc_info-no" autocomplete="off"
                         v-model.number="config.extend_doc_info">
                  <label class="btn btn-outline-primary" for="extend_doc_info-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="extend_doc_info-yes" autocomplete="off"
                         v-model.number="config.extend_doc_info">
                  <label class="btn btn-outline-primary" for="extend_doc_info-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>Dokumententyp in Info</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">

                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="send_time_documentation-no" autocomplete="off"
                         v-model.number="config.create_time_summary_employees">
                  <label class="btn btn-outline-primary" for="send_time_documentation-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="send_time_documentation-yes" autocomplete="off"
                         v-model.number="config.create_time_summary_employees">
                  <label class="btn btn-outline-primary" for="send_time_documentation-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>Mitarbeiter Zeitdokumentation automatisch versenden?</label><br>
              </div>
            </div>
            <!-- use_sepa_state field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_sepa_state-no" autocomplete="off"
                         v-model.number="config.use_sepa_state">
                  <label class="btn btn-outline-primary" for="use_sepa_state-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_sepa_state-yes" autocomplete="off"
                         v-model.number="config.use_sepa_state">
                  <label class="btn btn-outline-primary" for="use_sepa_state-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>Sepa in Liste</label>
              </div>
            </div>
            <!-- use_sepa_state field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="netto" class="btn-check" id="use_sepa_state-no" autocomplete="off"
                         v-model.number="config.document_brutto_netto">
                  <label class="btn btn-outline-primary" for="use_sepa_state-no">Netto</label>
                  <input type="radio" value="brutto" class="btn-check" id="use_sepa_state-yes" autocomplete="off"
                         v-model.number="config.document_brutto_netto">
                  <label class="btn btn-outline-primary" for="use_sepa_state-yes">Brutto</label>
                </div>
                <label>Netto/Brutto in Liste</label>
              </div>
            </div>
            <!-- use_legals_new field -->
            <div class="col-12">
              <div class="form-group">
                <label>Rechtliches aus CRM</label>
                <select id="use_legals_new" v-model="config.use_legals_new" class="form-select">
                  <option value="no">Nein</option>
                  <option value="yes">Ja</option>
                </select>
              </div>
            </div>
            <!-- doc_sending_note field -->
            <div class="col-12">
              <div class="form-group">
                <h6>{{ $t("globalSetting.documents.sendDocument") }} </h6>
                <p>{{ $t("globalSetting.documents.available") }} {{ $t("globalSetting.documents.salutation") }}
                  {{ $t("globalSetting.documents.title") }} {{ $t("globalSetting.documents.info") }}
                  {{ $t("globalSetting.documents.owner") }} {{ $t("globalSetting.documents.docnumber") }}
                  {{ $t("globalSetting.documents.date") }} </p>
              </div>
            </div>
            <!-- document_sent_subject field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.documentSendSubject") }} </label>
                <input type="text" id="document_sent_subject" v-model="config.document_sent_subject"
                       class="form-control">
              </div>
            </div>
            <!-- bcc_address_doc field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.bccAddressDoc") }}</label>
                <textarea id="bcc_address_doc" v-model="config.bcc_address_doc" class="form-control"></textarea>
              </div>
            </div>
            <!-- document_sent field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.documentSent") }} </label>
                <Editor2 v-model="config.document_sent"></Editor2>
              </div>
            </div>
            <!-- text_hint_documents field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.textHintDocuments") }} </label>
                <textarea id="text_hint_documents" v-model="config.text_hint_documents" class="form-control"></textarea>
              </div>
            </div>
            <!-- use_abschlag_function field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_abschlag_function-no" autocomplete="off"
                         v-model.number="config.use_abschlag_function">
                  <label class="btn btn-outline-primary"
                         for="use_abschlag_function-no">{{ $t("globalSetting.documents.useStrongTitleNo") }} </label>
                  <input type="radio" value="1" class="btn-check" id="use_abschlag_function-yes" autocomplete="off"
                         v-model.number="config.use_abschlag_function">
                  <label class="btn btn-outline-primary"
                         for="use_abschlag_function-yes">{{ $t("globalSetting.documents.useStrongTitleYes") }} </label>
                </div>
                <label>{{ $t("globalSetting.documents.automaticInvoice") }}</label>
              </div>
            </div>
            <!-- use_strong_titles field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_strong_titles-no" autocomplete="off"
                         v-model.number="config.use_strong_titles">
                  <label class="btn btn-outline-primary"
                         for="use_strong_titles-no">{{ $t("globalSetting.documents.useStrongTitleNo") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_strong_titles-yes" autocomplete="off"
                         v-model.number="config.use_strong_titles">
                  <label class="btn btn-outline-primary"
                         for="use_strong_titles-yes">{{ $t("globalSetting.documents.useStrongTitleYes") }}</label>
                </div>
                <label>{{ $t("globalSetting.documents.invoiceHeadings") }} </label>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- pay_due_days field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.payDueDays") }})</label>
                <input type="number" id="pay_due_days" v-model="config.pay_due_days" class="form-control">
              </div>
            </div>
            <!-- pay_default_percentage field -->
            <div class="col-12" v-if="config.pay_due_days">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.payDefaultPercentage") }}</label>
                <input type="number" id="pay_default_percentage" v-model="config.pay_default_percentage"
                       class="form-control">
              </div>
            </div>
            <!-- datev_numbers_booking field -->
            <div class="col-12">
              <div class="form-group">
                <h6>{{ $t("globalSetting.documents.dateV") }}</h6>
              </div>
            </div>
            <!-- datev_ger_in field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.dateVGerIn") }}</label>
                <input type="text" id="datev_ger_in" v-model="config.datev_ger_in" class="form-control">
              </div>
            </div>
            <!-- datev_eu_in field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.dateVEuIn") }}</label>
                <input type="text" id="datev_eu_in" v-model="config.datev_eu_in" class="form-control">
              </div>
            </div>
            <!-- datev_eu_out field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.dateVEuOut") }}</label>
                <input type="text" id="datev_eu_out" v-model="config.datev_eu_out" class="form-control">
              </div>
            </div>
            <!-- datev_gegenkonto field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.dateVContraAccount") }}</label>
                <input type="text" id="datev_gegenkonto" v-model="config.datev_gegenkonto" class="form-control">
              </div>
            </div>
            <!-- customTypesNote field -->
            <div class="col-12">
              <div class="form-group">
                <h6>{{ $t("globalSetting.documents.documentUpload") }}</h6>
              </div>
            </div>
            <!-- customTypeEmailNotification field -->
            <div class="col-12">
              <div class="form-group">
                <label>{{ $t("globalSetting.documents.customTypeEmailNotify") }}</label>
                <input type="email" id="customTypeEmailNotification" v-model="config.customTypeEmailNotification"
                       class="form-control">
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="activeSubtab === 'customer'">
          <div class="row">
            <!-- delete_time field -->
            <div class="col-12">
              <div class="form-group">
                <label for="delete_time">{{ $t("globalSetting.customer.deleteTime") }}</label>
                <input id="delete_time" v-model="config.delete_time" type="text" class="form-control"/>
              </div>
            </div>
            <!-- customer_salutation field -->
            <div class="col-12">
              <div class="form-group">
                <label for="customer_salutation">{{ $t("globalSetting.customer.customerSalutation") }}</label>
                <input id="customer_salutation" v-model="config.customer_salutation" type="text" class="form-control"/>
              </div>
            </div>
            <!-- customer_saluation_type field -->
            <div class="col-12">
              <div class="form-group">
                <label for="customer_saluation_type">{{ $t("globalSetting.customer.customerSalutationType") }}</label>
                <select id="customer_saluation_type" v-model="config.customer_saluation_type" class="form-select">
                  <option value="titlefirst">{{ $t("globalSetting.customer.titleFirst") }}</option>
                  <option value="onlyfirst">{{ $t("globalSetting.customer.onlyFirst") }}</option>
                  <option value="titlelast">{{ $t("globalSetting.customer.titleLast") }}</option>
                  <option value="onlylast">{{ $t("globalSetting.customer.onlyLast") }}</option>
                  <option value="titlefirstlast">{{ $t("globalSetting.customer.titleFirstLast") }}</option>
                </select>
              </div>
            </div>
            <!-- customer_salutation_end field -->
            <div class="col-12">
              <div class="form-group">
                <label for="customer_salutation_end">{{ $t("globalSetting.customer.customerSalutationEnd") }}</label>
                <input id="customer_salutation_end" v-model="config.customer_salutation_end" type="text"
                       class="form-control"/>
              </div>
            </div>
            <!-- use_notes field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_notes-no" autocomplete="off"
                         v-model="config.use_notes">
                  <label class="btn btn-outline-primary" for="use_notes-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_notes-yes" autocomplete="off"
                         v-model="config.use_notes">
                  <label class="btn btn-outline-primary" for="use_notes-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.useNoteSystem") }}</label>
              </div>
            </div>
            <!-- use_coords field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_coords-no" autocomplete="off"
                         v-model="config.use_coords">
                  <label class="btn btn-outline-primary" for="use_coords-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_coords-yes" autocomplete="off"
                         v-model="config.use_coords">
                  <label class="btn btn-outline-primary" for="use_coords-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.determineCoordinates") }}</label>
              </div>
            </div>
            <div class="col-12">
              <hr>
              <h6>{{ $t("globalSetting.customer.customerOverview") }}</h6></div>
            <!-- use_employeeicon_listing field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_employeeicon_listing-no" autocomplete="off"
                         v-model="config.use_employeeicon_listing">
                  <label class="btn btn-outline-primary" for="use_employeeicon_listing-no">{{
                      $t("globals.no")
                    }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_employeeicon_listing-yes" autocomplete="off"
                         v-model="config.use_employeeicon_listing">
                  <label class="btn btn-outline-primary" for="use_employeeicon_listing-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.supervisorIcon") }}</label>
              </div>
            </div>
            <!-- use_employeeicon_listing field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_birthday_listing-no" autocomplete="off"
                         v-model="config.use_birthday_listing">
                  <label class="btn btn-outline-primary" for="use_birthday_listing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_birthday_listing-yes" autocomplete="off"
                         v-model="config.use_birthday_listing">
                  <label class="btn btn-outline-primary" for="use_birthday_listing-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>{{ $t("globals.birthDate") }}</label>
              </div>
            </div>
            <!-- use_createdon_listing field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_createdon_listing-no" autocomplete="off"
                         v-model="config.use_createdon_listing">
                  <label class="btn btn-outline-primary" for="use_createdon_listing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_createdon_listing-yes" autocomplete="off"
                         v-model="config.use_createdon_listing">
                  <label class="btn btn-outline-primary" for="use_createdon_listing-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>{{ $t("globals.creationDate") }}</label>
              </div>
            </div>
            <!-- use_last_activity_listing field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_last_activity_listing-no" autocomplete="off"
                         v-model="config.use_last_activity_listing">
                  <label class="btn btn-outline-primary" for="use_last_activity_listing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_last_activity_listing-yes" autocomplete="off"
                         v-model="config.use_last_activity_listing">
                  <label class="btn btn-outline-primary" for="use_last_activity_listing-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>{{ $t("globals.lastActivity") }}</label>


              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_custype_listing-no" autocomplete="off"
                         v-model="config.use_custype_listing">
                  <label class="btn btn-outline-primary" for="use_custype_listing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_custype_listing-yes" autocomplete="off"
                         v-model="config.use_custype_listing">
                  <label class="btn btn-outline-primary" for="use_custype_listing-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>{{ $t("globals.customerType") }}</label>
              </div>
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_akquise_listing-no" autocomplete="off"
                         v-model="config.use_akquise_listing">
                  <label class="btn btn-outline-primary" for="use_akquise_listing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_akquise_listing-yes" autocomplete="off"
                         v-model="config.use_akquise_listing">
                  <label class="btn btn-outline-primary" for="use_akquise_listing-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>Akquisestand anzeigen</label>
              </div>
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_prio_listing-no" autocomplete="off"
                         v-model="config.use_prio_listing">
                  <label class="btn btn-outline-primary" for="use_prio_listing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_prio_listing-yes" autocomplete="off"
                         v-model="config.use_prio_listing">
                  <label class="btn btn-outline-primary" for="use_prio_listing-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>Priorität anzeigen</label>
              </div>
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_customernr_listing-no" autocomplete="off"
                         v-model="config.use_customernr_listing">
                  <label class="btn btn-outline-primary" for="use_customernr_listing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_customernr_listing-yes" autocomplete="off"
                         v-model="config.use_customernr_listing">
                  <label class="btn btn-outline-primary" for="use_customernr_listing-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>Kundennummer anzeigen</label>
              </div>
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_country_listing-no" autocomplete="off"
                         v-model="config.use_country_listing">
                  <label class="btn btn-outline-primary" for="use_country_listing-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_country_listing-yes" autocomplete="off"
                         v-model="config.use_country_listing">
                  <label class="btn btn-outline-primary" for="use_country_listing-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>Länder anzeigen</label>
              </div>
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_old_customer_ids-no" autocomplete="off"
                         v-model="config.use_old_customer_ids">
                  <label class="btn btn-outline-primary" for="use_old_customer_ids-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_old_customer_ids-yes" autocomplete="off"
                         v-model="config.use_old_customer_ids">
                  <label class="btn btn-outline-primary" for="use_old_customer_ids-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>Gelöschte Kundennummern wieder auffüllen</label>
              </div>
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="default_owner_filter-no" autocomplete="off"
                         v-model="config.default_owner_filter">
                  <label class="btn btn-outline-primary" for="default_owner_filter-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="default_owner_filter-yes" autocomplete="off"
                         v-model="config.default_owner_filter">
                  <label class="btn btn-outline-primary" for="default_owner_filter-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>Standardmäßig nach Betreuer Filtern</label>
              </div>
              <!-- use_funktion_as_empty_checker field -->
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_funktion_as_empty_checker-no"
                         autocomplete="off" v-model="config.use_funktion_as_empty_checker">
                  <label class="btn btn-outline-primary" for="use_funktion_as_empty_checker-no">{{
                      $t("globals.no")
                    }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_funktion_as_empty_checker-yes"
                         autocomplete="off" v-model="config.use_funktion_as_empty_checker">
                  <label class="btn btn-outline-primary" for="use_funktion_as_empty_checker-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.functionAsRequired") }}</label>
              </div>
              <!-- address_tags field -->
              <div class="form-group">
                <label for="address_tags">{{ $t("globalSetting.customer.addressTags") }}</label>
                <textarea id="address_tags" v-model="config.address_tags" class="form-control"></textarea>
              </div>
              <!-- customer_default_values_c_owner field -->
              <div class="form-group">
                <label for="customer_default_values_c_owner">{{ $t("globalSetting.customer.employeeSelect") }}</label>
                <Multiselect v-model="config.customer_default_values_c_owner" :label="'e_name'" :options="getEmployees"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Betreuer wählen'"
                             :trackBy="'id'"
                ></Multiselect>
              </div>
              <!-- customer_category_default field -->
              <div class="form-group">
                <label for="customer_category_default">{{ $t("globalSetting.customer.categoryDefault") }}</label>
                <Multiselect v-model="config.customer_category_default" :label="'title'"
                             :options="getConfigs.categories_customers"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Kategorie wählen'"
                             :trackBy="'title'"
                ></Multiselect>
              </div>
              <!-- use_joomlauser field -->
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_joomlauser-no" autocomplete="off"
                         v-model="config.use_joomlauser">
                  <label class="btn btn-outline-primary" for="use_joomlauser-no">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_joomlauser-yes" autocomplete="off"
                         v-model="config.use_joomlauser">
                  <label class="btn btn-outline-primary" for="use_joomlauser-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.useCustomerAccount") }}</label>
              </div>
              <!-- use_storno_umkehr field -->
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_storno_umkehr-no" autocomplete="off"
                         v-model="config.use_storno_umkehr">
                  <label class="btn btn-outline-primary" for="use_storno_umkehr-no">Nein</label>
                  <input type="radio" value="1" class="btn-check" id="use_storno_umkehr-yes" autocomplete="off"
                         v-model="config.use_storno_umkehr">
                  <label class="btn btn-outline-primary" for="use_storno_umkehr-yes">{{ $t("globals.yes") }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.cancellationReversePosting") }}</label>
              </div>
            </div>
            <!-- use_joomlauser_activation field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="use_joomlauser_activation-no" autocomplete="off"
                         v-model="config.use_joomlauser_activation">
                  <label class="btn btn-outline-primary" for="use_joomlauser_activation-no">{{
                      $t("globals.no")
                    }}</label>
                  <input type="radio" value="1" class="btn-check" id="use_joomlauser_activation-yes" autocomplete="off"
                         v-model="config.use_joomlauser_activation">
                  <label class="btn btn-outline-primary" for="use_joomlauser_activation-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.emailVerification") }}</label>
              </div>
            </div>
            <!-- defaultjoomlausergroup field -->
            <div class="col-12">
              <div class="form-group">
                <label for="defaultjoomlausergroup">{{ $t("globalSetting.customer.defaultGroup") }}</label>
                <input type="text" id="defaultjoomlausergroup" v-model="config.defaultjoomlausergroup"
                       class="form-input"/>
              </div>
            </div>
            <!-- website_url field TODO-ralf scope super-user -->
<!--            <div class="col-12">-->
<!--              <div class="form-group">-->
<!--                <label for="website_url">{{ $t("globalSetting.customer.websiteUrl") }}</label>-->
<!--                <input id="website_url" v-model="config.website_url" type="text" class="form-control"/>-->
<!--              </div>-->
<!--            </div>-->
            <!-- api_url field -->
            <div class="col-12">
              <div class="form-group">
                <label for="api_url">{{ $t("globalSetting.customer.apiUrl") }}</label>
                <input id="api_url" v-model="config.api_url" type="text" class="form-control"/>
              </div>
            </div>
            <!-- portal_url_dirty field -->
            <div class="col-12">
              <div class="form-group">
                <label for="portal_url_dirty">{{ $t("globalSetting.customer.portalUrlDirty") }}</label>
                <input id="portal_url_dirty" v-model="config.portal_url_dirty" type="text" class="form-control"/>
              </div>
            </div>
            <!-- portal_url_pretty field -->
            <div class="col-12">
              <div class="form-group">
                <label for="portal_url_pretty">{{ $t("globalSetting.customer.portalUrlPretty") }}</label>
                <input id="portal_url_pretty" v-model="config.portal_url_pretty" type="text" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- note_notifications field -->
            <div class="col-12">
              <h6>{{ $t("globalSetting.customer.notifySettings") }}</h6>
            </div>
            <!-- send_mail_after_ownerChange field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="send_mail_after_ownerChange-no" autocomplete="off"
                         v-model="config.send_mail_after_ownerChange">
                  <label class="btn btn-outline-primary" for="send_mail_after_ownerChange-no">{{
                      $t("globals.no")
                    }}</label>
                  <input type="radio" value="1" class="btn-check" id="send_mail_after_ownerChange-yes"
                         autocomplete="off" v-model="config.send_mail_after_ownerChange">
                  <label class="btn btn-outline-primary" for="send_mail_after_ownerChange-yes">{{
                      $t("globals.yes")
                    }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.notifyEmployees") }}</label>
              </div>
            </div>
            <!-- note_redirects field -->
            <div class="col-12">
              <h6>{{ $t("globalSetting.customer.redirectSettings") }}</h6>
            </div>
            <!-- redirect_url_after_activation field -->
            <div class="col-12">
              <div class="form-group">
                <label for="redirect_url_after_activation">{{ $t("globalSetting.customer.urlRedirect") }}</label>
                <input id="redirect_url_after_activation" v-model="config.redirect_url_after_activation" type="text"
                       class="form-control"/>
              </div>
            </div>
            <!-- activation_url field -->
            <div class="col-12">
              <div class="form-group">
                <label for="activation_url">{{ $t("globalSetting.customer.activationUrl") }}</label>
                <input id="activation_url" v-model="config.activation_url" type="text" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="form-group"><label>{{ $t('edit.structure1') }} Titel</label><input type="text"
                                                                                             v-model="config.title_structure1"
                                                                                             class="form-control"/>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group"><label>{{ $t('edit.structure2') }} Titel</label><input type="text"
                                                                                             v-model="config.title_structure2"
                                                                                             class="form-control"/>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group"><label>{{ $t('edit.structure3') }} Titel</label><input type="text"
                                                                                             v-model="config.title_structure3"
                                                                                             class="form-control"/>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group"><label>{{ $t('edit.structure4') }} Titel</label><input type="text"
                                                                                             v-model="config.title_structure4"
                                                                                             class="form-control"/>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="activeSubtab === 'pdf'">
          <div class="row">
            <!-- inc_adresshead field -->
            <div class="col-12">
              <div class="form-group">
                <label for="inc_adresshead">{{ $t("globalSetting.customer.addressHead") }}</label>
                <input id="inc_adresshead" v-model="config.inc_adresshead" type="text" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12" v-if="config">
              <div class="form-group">
                <label>Logo Rechnung/Dokumente <small> (Empfohlene Maße: 200px Breite x 110px Höhe)</small></label>

                <input type="file" ref="fileInput" class="form-control" @change="onImageChange" accept="images/*"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group" v-if="config.image_rechnung">
                <label class="mb-2">Aktuelles Bild</label>
                <br>
                <template v-if="showImage === false">
                  <button class="btn btn-outline-primary me-3" @click="showImage = true" tooltip="Bild anzeigen"><i
                    class="fa-duotone fa-image"></i></button>
                </template>
                <template v-else>
                  <button class="btn btn-primary me-3" @click="showImage = false"><i
                    class="fa-duotone fa-image"></i></button>
                </template>
                <button class="btn btn-danger" @click="config.imageSource = ''; config.imageMimeType = '';config.image_rechnung = ''"
                        tooltip="Bild löschen"><i
                  class="fa-duotone fa-image-slash"></i></button>
                <div v-if="showImage">
                  <template v-if="config.imageSource !== '' && config.imageMimeType !=='' && config.imageMimeType !== undefined && config.imageSource !== undefined">
                    <img :src="'data:'+config.imageMimeType+';base64,'+config.imageSource" :alt="config.imageMimeType"/>
                  </template>
                  <template v-else>
                    <img :src="config.image_rechnung" alt=""/>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- inc_footer field -->
            <div class="col-12">
              <div class="form-group">
                <button type="button" class="btn btn-sm float-end" @click="toggleEditor"
                        :class="(editorMode === 'html') ? 'btn-primary' :  'btn-outline-primary'"><i
                  class="fa-duotone fa-code"></i> Editor
                </button>
                <label for="inc_footer" class="mb-3">{{ $t("globalSetting.customer.footer") }}</label>
                <div :key="editorKey" v-if="config">
                  <div :class="editorMode" >
                    <EditorHtml v-model="config.inc_footer" :languages="[['html','HTML'],['css','CSS']]"/>
                  </div>

                </div>

              </div>
            </div>
            <!-- inc_hinweise_angebot field -->
            <div class="col-12">
              <div class="form-group">
                <label for="inc_hinweise_angebot">{{ $t("globalSetting.customer.offerInfo") }}</label>
                <Editor2 v-model="config.inc_hinweise_angebot"></Editor2>
              </div>
            </div>
            <!-- table_base_font_size_pdf field -->
            <div class="col-12">
              <div class="form-group">
                <label for="table_base_font_size_pdf">{{ $t("globalSetting.customer.baseFontSize") }}</label>
                <input id="table_base_font_size_pdf" v-model="config.table_base_font_size_pdf" type="number"
                       class="form-control"/>
              </div>
            </div>
            <!-- small_base_font_size_pdf field -->
            <div class="col-12">
              <div class="form-group">
                <label for="small_base_font_size_pdf">{{ $t("globalSetting.customer.baseFontSizeSmall") }}</label>
                <input id="small_base_font_size_pdf" v-model="config.small_base_font_size_pdf" type="number"
                       class="form-control"/>
              </div>
            </div>
            <!-- heading_base_font_size_pdf field -->
            <div class="col-12">
              <div class="form-group">
                <label for="heading_base_font_size_pdf">{{ $t("globalSetting.customer.baseFontSizeHeading") }}</label>
                <input id="heading_base_font_size_pdf" v-model="config.heading_base_font_size_pdf" type="number"
                       class="form-control"/>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- enter_new_products field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="top" class="btn-check" id="enter_new_products-top" autocomplete="off"
                         v-model="config.enter_new_products">
                  <label class="btn btn-outline-primary" for="enter_new_products-top">Top</label>
                  <input type="radio" value="down" class="btn-check" id="enter_new_products-down" autocomplete="off"
                         v-model="config.enter_new_products">
                  <label class="btn btn-outline-primary" for="enter_new_products-down">Down</label>
                </div>
                <label>{{ $t("globalSetting.customer.newProducts") }}</label>
              </div>
            </div>
            <!-- document_item_sorting field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="default" class="btn-check" id="document_item_sorting-default"
                         autocomplete="off" v-model="config.document_item_sorting">
                  <label class="btn btn-outline-primary"
                         for="document_item_sorting-default">{{
                      $t("globalSetting.customer.itemSortingDefault")
                    }}</label>
                  <input type="radio" value="alphabetic" class="btn-check" id="document_item_sorting-alphabetic"
                         autocomplete="off" v-model="config.document_item_sorting">
                  <label class="btn btn-outline-primary"
                         for="document_item_sorting-alphabetic">{{
                      $t("globalSetting.customer.itemSortingAlphabetic")
                    }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.sortDocument") }}</label>
              </div>
            </div>
          </div>
          <hr>
          <h6>{{ $t("globalSetting.customer.voucher") }}</h6>
          <div class="row">
            <!-- voucher_image field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_image">{{ $t("globalSetting.customer.voucherImage") }}</label>
                <input id="voucher_image" v-model="config.voucher_image" type="text" class="form-control"/>
              </div>
            </div>
            <!-- voucher_code_position_x field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_code_position_x">{{ $t("globalSetting.customer.voucherCodePosX") }}</label>
                <input id="voucher_code_position_x" v-model="config.voucher_code_position_x" type="number"
                       class="form-control"/>
              </div>
            </div>
            <!-- voucher_code_position_y field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_code_position_y">{{ $t("globalSetting.customer.voucherCodePosY") }}</label>
                <input id="voucher_code_position_y" v-model="config.voucher_code_position_y" type="number"
                       class="form-control"/>
              </div>
            </div>
            <!-- voucher_value_position_x field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_value_position_x">{{ $t("globalSetting.customer.voucherPosX") }}</label>
                <input id="voucher_value_position_x" v-model="config.voucher_value_position_x" type="number"
                       class="form-control"/>
              </div>
            </div>
            <!-- voucher_value_position_y field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_value_position_y">{{ $t("globalSetting.customer.voucherPosY") }}</label>
                <input id="voucher_value_position_y" v-model="config.voucher_value_position_y" type="number"
                       class="form-control"/>
              </div>
            </div>
            <!-- voucher_font_color field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_font_color">{{ $t("globalSetting.customer.voucherFontColor") }}</label>
                <input id="voucher_font_color" v-model="config.voucher_font_color" type="color" class="form-control"/>
              </div>
            </div>
            <!-- voucher_font_size field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_font_size">{{ $t("globalSetting.customer.voucherFontSize") }}</label>
                <input id="voucher_font_size" v-model="config.voucher_font_size" type="number" class="form-control"/>
              </div>
            </div>
            <!-- voucher_send_title field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_send_title">{{ $t("globalSetting.customer.voucherSendTitle") }}</label>
                <input id="voucher_send_title" v-model="config.voucher_send_title" type="text" class="form-control"/>
              </div>
            </div>
            <!-- voucher_send_text field -->
            <div class="col-12">
              <div class="form-group">
                <label for="voucher_send_text">{{ $t("globalSetting.customer.voucherSendText") }}</label>
                <Editor2 id="voucher_send_text" v-model="config.voucher_send_text"></Editor2>
              </div>
            </div>
            <!-- note_invoice_paid field -->
            <div class="col-12">
              <h6>{{ $t("globalSetting.customer.paidMail") }}</h6>
            </div>
            <!-- send_invoice_paid_mail field -->
            <div class="col-12">
              <div class="form-group">
                <div class="btn-group me-3">
                  <input type="radio" value="1" class="btn-check" id="send_invoice_paid_mail-yes" autocomplete="off"
                         v-model.number="config.send_invoice_paid_mail">
                  <label class="btn btn-outline-primary" for="send_invoice_paid_mail-yes">{{
                      $t("globals.yes")
                    }}</label>
                  <input type="radio" value="0" class="btn-check" id="send_invoice_paid_mail-no" autocomplete="off"
                         v-model.number="config.send_invoice_paid_mail">
                  <label class="btn btn-outline-primary" for="send_invoice_paid_mail-no">{{ $t("globals.no") }}</label>
                </div>
                <label>{{ $t("globalSetting.customer.usePaidMail") }}</label>
              </div>
            </div>
            <!-- email_subject_invoice_paid field -->
            <div class="col-12">
              <div class="form-group">
                <label for="email_subject_invoice_paid">{{ $t("globalSetting.customer.paidMailSubject") }}</label>
                <input id="email_subject_invoice_paid" v-model="config.email_subject_invoice_paid" type="text"
                       class="form-control"/>
              </div>
            </div>
            <!-- email_text_invoice_paid field -->
            <div class="col-12">
              <div class="form-group">
                <label for="email_text_invoice_paid">{{ $t("globalSetting.customer.paidMailText") }}</label>
                <Editor2 id="email_text_invoice_paid" v-model="config.email_text_invoice_paid"></Editor2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Editor2 from "@/components/Editor2";
import axios from "axios";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";
import EditorHtml from "@/components/EditorHtml";
import Multiselect from '@vueform/multiselect'

export default {
  name: "GlobalConfig",
  data() {
    return {
      activeSubtab: 'general',
      configTabs: [
        {name: 'general', label: "Global", icon: 'fa-cog'},
        {name: 'numbers', label: "Nummernkreise", icon: 'fa-hashtag'},
        {name: 'emails', label: "E-Mails", icon: 'fa-envelope'},
        {name: 'pdf', label: "PDF", icon: 'fa-file-pdf'},
        {name: 'document', label: "Dokument", icon: 'fa-file-spreadsheet'},
        {name: 'customer', label: "Kunden", icon: 'fa-user-tie'},
      ],
      config: {
        imageSource: '',
        imageMimeType: '',
      },
      editorMode: 'default',
      editorKey: null,
      showImage: false
    }
  },
  computed: {
    ...mapGetters(['getApiUrl', 'getEmployees', 'getConfigs']),
  },
  components: {
    Editor2, EditorHtml, Multiselect
  },
  methods: {
    toggleEditor() {
      this.editorKey = new Date().getTime();
      this.$nextTick(() => {
        this.editorMode = this.editorMode === 'default' ? 'html' : 'default';
      });
    },
    getConfigLocal() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let getUrl = this.$store.getters.getApiUrl + "config/params/values";
      getUrl = getUrl + "?limit=0";
      axios.get(getUrl, {headers}).then(async (response) => {
        let shortResponse = response.data.result;
        let key = 'params_values';
        await IndexedDbFunctions.addObject(this.$store.state.dbName, this.$store.state.dbVersion, 'config', {
          id: key,
          data: shortResponse.data
        });
        this.config = shortResponse.data;
      }).catch(function () {
      });
    },
    changeTab(tab) {
      this.activeSubtab = tab;
    },
    onImageChange() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0]; // Get the selected file
      let VueInner = this;
      if (file) {
        // Read the file as a Data URL to get base64 data
        const reader = new FileReader();
        reader.onload = () => {
          VueInner.config.imageMimeType = file.type; // Set the image MIME type
          VueInner.config.imageSource = reader.result.split(',')[1]; // Set the base64 image data
        };
        reader.readAsDataURL(file);
      }
    },
    saveConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "config/params", this.config, {headers}).then((r) => {
        let key = 'params_values';
        VueInner.$store.dispatch('toastSuccess', 'Einstellung wurde erfolgreich gespeichert');
        IndexedDbFunctions.addObject(this.$store.state.dbName, this.$store.state.dbVersion, 'config', {
          id: key,
          data: r.data.result.data
        });
        this.config = r.data.result.data;
        this.$router.push({name: 'ConfigBasic'});
      }).catch((error) => {
        this.$store.dispatch("handleApiResponse", error.response);
        this.$router.push({name: 'ConfigBasic'});
      });
    }
  },
  beforeMount() {
    this.getConfigLocal();
  }
}
</script>

