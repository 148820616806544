<template>
  <div class="card mb-3">
    <div class="card-header"><i class="fa-duotone fa-file-circle-info"></i> Berichte</div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3 col-12">
          <div class="form-group">
            <label for="search">Suche</label>
            <input type="text" class="form-control small" id="search" v-model="filter_search" @blur="filter"
                   @keyup.enter="filter">
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="form-group">
            <label for="employee">Mitarbeiter</label>
            <select id="employee" class="form-select small" v-model="filter_owner_id" v-if="getEmployees"
                    @change="filter">
              <option v-for="employee in getEmployees" :value="employee.id" v-bind:key="employee.id">
                {{ employee.e_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="form-group">
            <label for="month">Monat</label>
            <select id="month" class="form-select small" v-model="filter_month" @change="filter">
              <option value="">Alle</option>
              <option value="01">Januar</option>
              <option value="02">Februar</option>
              <option value="03">März</option>
              <option value="04">April</option>
              <option value="05">Mai</option>
              <option value="06">Juni</option>
              <option value="07">Juli</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">Oktober</option>
              <option value="11">November</option>
              <option value="12">Dezember</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="form-group">
            <label for="year">Jahr</label>
            <select id="year" class="form-select small" v-model="filter_year" @change="filter">
              <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <table class="table-sm table table-striped" v-if="tableData">
        <thead>
        <tr>
          <th>Name</th>
          <th>erstellt</th>
          <th>Mitarbeiter</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="data in tableData">
          <td>{{ data.title }}</td>
          <td>{{ $filters.formatDate(data.crm_created_date) }}</td>
          <td><img style="max-width: 40px"
                   :src="$filters.employeeIcon(getEmployees, data.crm_created_employee_id, 'id')"
                   class="sketchAvatar"></td>
          <td style="text-align: right;">
            <button @click="downloadReport(data.id)" type="button" class="btn btn-outline-primary btn-sm  "
                ><i class="fa-duotone fa-download"></i> Download
            </button>
            <button @click="openCustomersAsBefore(data.id, data.structure_crm)" style="min-width: 150px;" type="button" class="btn btn-outline-primary btn-sm ms-3" ><i class="fa-duotone fa-users"></i> {{data._count}} Kunden
            </button>
            <button type="button" class="btn btn-outline-danger btn-sm ms-3" @click="deleteReport(data.id)" ><i class="fa-duotone fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="more_reports mt-4">

    <h6>Weitere Berichte</h6>
    <button class="btn btn-outline-primary me-3" @click="$router.push('/stats/newCustomers')"><i class="fa-duotone fa-user"></i> Neue Kunden</button>
    <button class="btn btn-outline-primary me-3"  v-if="getPlugins && getPlugins.extension && getPlugins.extension.bdfl" @click="$router.push('/stats/birthdays')"><i class="fa-duotone fa-birthday-cake"></i> Runde Geburtstage</button>
  </div>
</template>
<script>
import axios from 'axios';
import {mapGetters} from "vuex";

export default {
  name: 'reports',
  data() {
    return {
      tableData: [],
      selectedEmployee: null,
      selectedMonth: null,
      selectedYear: null,
      filter_query: '',
      filter_owner_id: '',
      filter_month: '',
      filter_year: '',
      filter_search: '',
      loading: false,

    }
  },
  computed: {
    ...mapGetters(["getEmployees", "getUser", "getPlugins"]),
    years() {
      let years = [];
      for (let i = 2020; i <= new Date().getFullYear(); i++) {
        years.push(i);
      }
      return years;
    }
  },
  methods: {
    filter() {
      this.filter_query = '';
      if (this.filter_owner_id) {
        this.filter_query += '?filter_owner=' + this.filter_owner_id;
      }
      if (this.filter_month) {
        this.filter_query += '&filter_month=' + this.filter_month;
      }
      if (this.filter_year) {
        this.filter_query += '&filter_year=' + this.filter_year;
      }
      if (this.filter_search) {
        this.filter_query += '&filter_search=' + this.filter_search;
      }
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "report/search" + this.filter_query, {headers}).then((r) => {
        VueInner.tableData = r.data.result.data;
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });
    },
    setDefaults() {
      if (this.getUser && this.getUser._isEmployee) {
        this.filter_owner_id = this.getUser._isEmployee;
      }
      // get Current month like this: 01, 02, 03, ...
      let date = new Date();
      this.filter_year = date.getFullYear();
      this.filter_month = ("0" + (date.getMonth() + 1)).slice(-2);

    },

    openCustomersAsBefore(id, structure) {
      this.$store.state.filters.customers.owner_id.array = [];
      this.$store.state.filters.customers.report.value = id;
      this.$store.state.filters.customers.report.structure = structure;
      this.$router.push({name: 'customersReport'});
    },
    downloadReport(id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "report/" + id + "/preview", {headers}).then((response) => {
        let file = response.data.result;

        const linkSource = 'data:' + file.mime + ';base64,' + file.data;
        var a = document.createElement('a');
        a.href = linkSource;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    deleteReport(id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "report/" + id, {headers}).then((response) => {
        this.$store.dispatch('toastSuccess', 'Bericht erfolgreich gelöscht.');
        this.filter();
      });
    }
  },
  created() {
    this.setDefaults();
    this.filter();
  }
}
</script>
