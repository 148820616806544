<template>

<div class="">

  <div class="row">

    <div class="col-12">



      <h3 class="mb-4 text-uppercase mt-5 text-done" v-if="(tasks && tasks.length)">Aufgaben</h3>

      <div class="task pt-3 pb-3" v-for="task in tasks" v-bind:key="task.id">

        <div class="row" v-if="task">


          <div class="col-lg-6">

            <p class="pb-0 mb-0 mt-2 text-done">{{ task.name }}</p>
            <p>
              <span class="mt-3 me-3 bagde btn " :class="(task.status === 'open') ? 'btn-warning' : 'btn-outline-warning btn-disabled'" @click="setTaskState(task.id, 'open')" >Offen</span>
              <span class="mt-3 me-3 bagde btn " :class="(task.status === 'completed') ? 'btn-info' : 'btn-outline-info btn-disabled'" @click="setTaskState(task.id, 'completed')" >Abgegeben</span>
              <span class="mt-3 me-3 bagde btn " :class="(task.status === 'denied') ? 'btn-danger' : 'btn-outline-danger btn-disabled'" @click="setTaskState(task.id, 'denied')" >Abgelehnt</span>
              <span class="mt-3 me-3 bagde btn " :class="(task.status === 'closed') ? 'btn-success' : 'btn-outline-success btn-disabled'" @click="setTaskState(task.id, 'closed')" >Abgeschlossen</span>

            </p>
            <p>

              <button @click="openCommentBoxTasks(task)" :class="(task.comment) ? 'btn-info' : 'btn-outline-info btn-disabled'" class="btn">Kommentar</button>
            </p>
            <!--<p class="text-muted">{{ task.description }}</p>-->

          </div>
          <div class="col-lg-6 justify-content-center align-self-center text-end">
            <div class="table-responsive">
            <table class="table w-100 table-sm" v-if="(task.type === 'upload' || task.type ==='optional') && task.files && task.files.length > 0">
              <thead>
              <tr style="text-align: right;">
                <th style="width: 250px;">Datei</th>
                <th>Hochgeladen am</th>
                <th class="text-center"> </th>
              </tr>
              </thead>
              <tbody>

              <tr v-for="(file, index) in task.files" :key="index" :data-id="file.id">
                <td>{{file.title}}</td>
                <td>{{$filters.formatDateTime(file.uploaded_on)}}</td>
                <td class=""> <span :tooltip="'Ansehen'" position="left" class="btn btn-outline-success btn-xs" @click="openTaskFile(file, task)"><span v-if="loadingFile === file.id" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><i class="fa-duotone fa-eye"></i></span> </td>
              </tr>
              </tbody>
            </table></div>
            <div v-if="task.type === 'choose'" class="col-lg-12">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;"><strong>Prio</strong>
                  <ul style="text-align: left;">
                    <li v-for="pr in showChoose(task.choose).prio" :key="pr.id">{{pr.title}}</li>
                  </ul></div>
                <div class="col-lg-6" style="text-align: left;"><strong>Keine Teilnahme möglich</strong>
                  <ul style="text-align: left;">
                    <li v-for="pr in showChoose(task.choose).noPrio" :key="pr.id">{{pr.title}}</li>
                  </ul></div>
              </div>
            </div>


          </div>

        </div>
      </div>

    </div>

  </div>




</div>
  <Modal v-if="activeTask">
    <div class="container">
      <div class="card p-4">
        <div class="row">
          <div class="col-12">
            <div class="editorDrafts ">
              <div class="row">
                <div class="col-12">
                  <h4>Gründe für Änderungen</h4>

                  <select @change="handleChange()" v-model="activeComment" name="" id="" class="form-select mb-4">
                    <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                    <option :value="draft" v-for="draft in drafts" :key="draft.id" >{{draft.title}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <div class="form-floating mb-3">
                    <input class="form-control" v-model="activeComment.title" type="text" />
                    <label for="floatingInput">Titel der Vorlage</label>
                  </div>
                </div>
                <div class="col-4">
                  <button class="btn btn-success" style="margin-top: 6px;" @click="saveActiveComment();"><template v-if="activeComment.id">Aktualisieren</template><template v-else>Neu anlegen</template></button>
                </div>
              </div>


            </div>
            <Editor2 v-model="activeTask.comment" />
            <div class="row">
              <div class="col-12 mt-4"> <button class="btn btn-danger" @click="activeTask = false; activeComment={title:'',draft:''}">Schließen</button>
                <button class="btn btn-success float-end" @click="setTaskComment(activeTask.id, activeTask.comment)">Speichern</button>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="activeFile && activeFile.content">

    <div class="container">
      <div class="card" style="padding:24px; max-width: 80%">

        <div class="row">
          <div class="col-lg-4"><button @click="download()" class="btn btn-danger">Download</button></div>
          <div class="col-lg-4">
            <button @click="rotateSinglePage()" class="btn btn-info me-2 ms-2">Drehen</button>
          </div>
          <div class="col-lg-4"><button @click="activeFile = null;otherFiles = []; tempTask = null;" class="btn btn-success float-end">Vorschau schließen</button></div>
        </div>
        <template v-if="otherFiles && otherFiles.length > 1 && tempTask">
          <div class="row">
            <div class="col-12"><table class="fileViewerTable">
              <tr v-for="(file, index) in otherFiles" :key="index" :data-id="file.id"  >
                <td>{{file.title}}</td>
                <td>{{$filters.formatDateTime(file.uploaded_on)}}</td>
                <td class=""> <span :tooltip="'Ansehen'" position="left" class="btn  btn-xs" :class="(activeFile.id === file.id) ? 'btn-success' : 'btn-outline-success'" @click="openTaskFile(file, tempTask)">
                  <span v-if="loadingFile === file.id" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <i class="fa-duotone fa-eye" v-else></i></span> </td>
              </tr>
            </table></div>
          </div>

        </template>



        <div style="position: relative;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;">
          <VuePdfEmbed id="flipable" v-if="activeFile.contentType === 'application/pdf'" :source="'data:'+activeFile.contentType+';base64,'+activeFile.content" type="" />
          <embed v-else  id="flipable" :src="'data:'+activeFile.contentType+';base64,'+activeFile.content">
        </div>
      </div>
    </div>

  </Modal>
</template>

<script>

import axios   from "axios";
import {mapGetters} from "vuex";
import VuePdfEmbed from "vue-pdf-embed";
import {ref} from "vue";
import Editor2 from "@/components/Editor2.vue";
export default {

  emits: ['refreshActiveRequest'],
  name: 'DFBTasks',
  props: {
    tasks: null,
    requestId: null
  },
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  data() {
    return {
      activeComment: {title: '', draft: ''},
      activeFile: null,
      degree: 0,
      activeTask: false,
      tempTask: null,
      drafts: [],
      otherFiles: [],
      loadingFile: null
    }
  },
  components: {
    VuePdfEmbed,Editor2
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl", "getApiAuth"]),

  },

  methods:{
    handleChange() {
		if (this.activeComment.draft) {
      this.activeTask.comment = this.activeComment.draft;
	}
		else {
			this.activeTask.comment = this.activeComment.title;
    }
    },

    getDraftReasons() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=36", {headers}).then((r) => {
        this.drafts = r.data.result.data;

        this.loading = false;
      });
    },
    saveActiveComment() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};

      this.$log.debug(this.$data);
      let payload = {
        title: this.activeComment.title,
        draft: this.activeTask.comment
      }
      if(this.activeComment.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "draft/" + this.activeComment.id, payload, {headers}).then(() => {
          // trigger Custom Event Dom refreshTasksDFB
          this.getDraftReasons();


          alert("gespeichert!");
        }) .catch(() => {

        });
      } else {
        payload.group_id = 36;
        axios.post(this.$store.state.apiUrl + "draft/", payload, {headers}).then(() => {
          this.getDraftReasons();
          alert("gespeichert!");
        }) .catch(() => {

        });
      }
    },
    rotateSinglePage()
    {
      this.degree = this.degree + 90;
      var viewerMain = document.getElementById("flipable");
      viewerMain.style.transform = "rotate(" + this.degree + "deg)";
    },
    download() {
      // force download pdf file
      var a = document.createElement("a"); //Create <a>
      a.href = "data:"+this.activeFile.contentType+";base64," + this.activeFile.content; //Image Base64 Goes here
      a.download = this.activeFile.title; //File name Here
      a.click(); //Downloaded file

    },
    showChoose(choose) {
      if (choose) {
      return JSON.parse(choose);
      }
      else {
        return {prio: [], noPrio: []};
      }
    },
    openCommentBoxTasks(task) {
      this.activeTask = task;
    },
    openTaskFile(file, task) {

      this.loadingFile = file.id;
      const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
      axios.get(this.getApiUrl + 'customer/' +  task.customer_id + "/readFile/" + file.id, {headers}).then((response) => {
        let shortReport = response.data.result;
        file.content = shortReport.data.content;
        file.contentType = shortReport.data.contentType;
        this.otherFiles = task.files;
        this.tempTask = task;
        this.activeFile = file;
        this.loadingFile = null;
      }).catch((e) => {
        this.$log.debug(e);
        this.loadingFile = null;
      });
    },

    setTaskState(id, payload) {
      const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
      axios.put(this.getApiUrl + 'task/' +  id + "/setState", {state:payload}, {headers}).then(() => {
       this.$emit('refreshActiveRequest');
      }).catch((e) => {
        this.$log.debug(e);
      });
    },
    setTaskComment(id, payload) {
      const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
      if (this.activeComment.id === undefined) {
        this.activeComment.id = 0;
      }
      axios.put(this.getApiUrl + 'task/' +  id + "/setComment", {comment:payload, comment_id:this.activeComment.id}, {headers}).then(() => {
        this.activeTask = false;
        this.activeComment= {title: '', draft: ''};

        this.$emit('refreshActiveRequest');
      }).catch((e) => {
        this.$log.debug(e);
      });
    },


  },
  created() {
  this.getDraftReasons();
    document.addEventListener('closeAllModals', this.activeFile = null);
  console.log("comewith", this.requestId)
  },
  beforeUnmount() {
    document.removeEventListener('closeAllModals', this.activeFile = null);
  },
}
</script>

<style lang="scss">

.task {

  border-bottom: 2px solid #f2f2f2;

  &:last-of-type {
    //border-bottom: none;
  }

}

.text-done {

  color: #333;

}
.task.pt-3.pb-3 {
  position: relative;
}

.task.pt-3.pb-3 .checkIt {
  position: absolute;
  right: 5px;
  top: 20px;
}
table.table-sm td{
  white-space: normal;
  font-size: 14px;
}
table.table.table-striped.table-values.table-sm.mb-4 tbody td:last-child {
  width: 32px !important;
}
table.table-sm th{
  padding: 0.25rem !important;
}
table.table-sm span.btn{
  padding: 5px 5px;
}
.btn-disabled {
  background: #eee;
  color: #333;

    border-color: #eee;

}
</style>
