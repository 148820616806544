<template>
  <div class="card-body draft-list">
    <Sort @sort="resortOrder($event)" />
    <p class="text-sm text-secondary p-3" v-if="filteredDrafts.length === 0">Bitte nach Einträgen suchen.</p>
    <div class="draft-item" v-for="draft in filteredDrafts" :key="draft.id">
      <Headline :draft="draft"/>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Details from "@/routing/core/Config/Accounting/AccountingKeys/Details.vue";
import Headline from "@/routing/core/Config/Accounting/AccountingKeys/Layouts/Headline.vue";
import Sort from "@/routing/core/Config/Accounting/AccountingKeys/Layouts/Sort.vue";
import $ from "jquery";
import Pagination from "@/filter/components/pagination.vue";


export default {
  name: "AccountingKeysOverview",
  computed: {
    ...mapGetters(["getEmployees", "getConfigs"   ]),
    filteredDrafts() {
      // Ensure there's a search value and getConfigs.accountingKeys is available
      if (!this.$store.state.filters.accountingKeys.search.value || !this.getConfigs || this.getConfigs.accountingKeys.length === 0) {
        return [];
      }

      const searchValue = this.$store.state.filters.accountingKeys.search.value.toLowerCase();
      // Filter after 3 characters
      if (searchValue.length < 3) {
        return [];
      } else {
        return this.getConfigs.accountingKeys.filter(draft => draft.title.toLowerCase().includes(searchValue));
      }

    }
  },

  components: {
    Pagination,
    Sort,
    Headline,
    Details,
  },
  methods: {


    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.accountingKeys.ordering.value = payload.type;
        this.$store.state.filters.accountingKeys.direction.value = payload.direction;
      } else {
        this.$store.state.filters.accountingKeys.ordering.value = payload.type
      }

    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },

  created() {


  },
  watch: {
    $route(to, from) {

    },
    draftActive() {
    }
  }

};
</script>
