<template>
  <div class="card-body customer-list">
    <Sort @sort="resortOrder($event)" />
    <Details v-if="customerActive && customerActive.id"/>

    <div ref="scroll" class="mainscroller" :class="(activeCustomer) ? 'active' : ''" >
      <template v-if="$store.state.lists.customers.loading">
<preLoader height="70vh" style="background: transparent;position: relative;" class="inner" :loading="$store.state.lists.customers.loading" />
      </template>
      <template v-else>
      <p class="text-sm text-secondary" v-if="!customerList || customerList.data.length === 0">{{$t("list.customerNotFound")}}</p>
      <div :id="'customer_'+customer.id" class="customer-item"
           :class="(activeCustomer && activeCustomer === customer.id) ? 'activeCustomer' : 'notActive'"
           v-for="customer in customerList.data" :key="customer.id">
        <Headline :customer="customer"/>
      </div>
      </template>
    </div>
    <pagination list="customers" listName="customerList" filterName="customersFilter"  />

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Headline from "@/routing/core/Customers/Layouts/Headline";
import Details from "@/routing/core/Customers/Details";
import $ from "jquery";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Customers/Layouts/Sort.vue";
import preLoader from "@/components/Loading.vue";

export default {
  name: "CustomerList",
  computed: {
    ...mapGetters(["getEmployees", "customerList", "customerActive", "getUser", "getConfigs"])
  },
  data() {
    return {
      selectedCustomer: null,
      activeCustomer: null,
      loading: false,

    }
  },
  components: {
    Pagination,
    Details,
    Headline,
    Sort,
    preLoader
  },
  methods: {

    getCustomers(){
      this.loading = true;
      this.$store.dispatch("customersFilter");

    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.customers.ordering.value = payload.type;
        this.$store.state.filters.customers.direction.value = payload.direction;
      } else {
        this.$store.state.filters.customers.ordering.value = payload.type
      }
      this.getCustomers();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    if(this.getUser && this.getUser._isEmployee && this.getConfigs.params_values && this.getConfigs.params_values.default_owner_filter ==='1'){
      if(this.$route.name === 'customers' && this.$route.params.id === undefined){
        // only push emp if filter is empty
        if(this.$store.state.filters.customers.owner_id.array.length === 0){
          this.$store.state.filters.customers.owner_id.array.push(this.getUser._isEmployee);
        }

      }
      this.$nextTick(() => {
        this.getCustomers();
      });
    } else {
    this.getCustomers();
    }
    this.activeCustomer = this.$route.params.id;
    if (this.activeCustomer) {

      this.$store.dispatch("setActiveCustomer", this.activeCustomer);
    } else {
      this.$store.dispatch("setActiveCustomer", null);
    }
    if(this.activeCustomer === undefined){
      this.activeCustomer = null;
      this.$store.dispatch("setActiveCustomer", null);

    }

  },
  watch: {
    // watch router params id
    customerActive() {
      if (this.customerActive && this.customerActive.id) {
        this.activeCustomer = this.customerActive.id;
      } else {
        this.activeCustomer = null;
      }
    },

  }
};
</script>
