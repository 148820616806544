<template>


  <div class="card w-100">


    <h6 class="card-header">Aufgaben <button @click="activeTaskType = {id:'new'}" class="btn btn-outline-primary btn-sm float-end"><i class="fa-duotone fa-plus"></i> Aufgabe anlegen</button></h6>



    <div class="card-body" v-if="!activeTaskType">

      <div class="row">
        <div class="col-12">
          <div class="singleRequest" v-for="task in taskTypes" :key="task.id">

            <div class="card mb-3">

              <div class="card-body">

                <div class="row">


                  <div class="col-2">

                    <span class="badge badge-primary w-100">{{ task.type }}</span>

                  </div>

                  <div class="col-8">

                    <p class="text-secondary text-sm mb-0">{{ task.name }}</p>

                  </div>

                  <div class="col-2">

                    <button @click="activeTaskType = task" class="btn btn-outline-primary btn-xs float-end" tooltip="Editieren" position="left"><i class="fa-duotone fa-edit"></i></button>

                  </div>

                </div>


              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="row">
        <div class="col-12">

      <h5>{{ activeTaskType.name }}</h5>
      <button @click="activeTaskType = null" class="btn btn-outline-secondary float-end "><i class="fa-duotone fa-question"></i> Zu den Fragen</button>
      <button @click="postOrPut()" class="btn btn-outline-success float-end me-3"><i class="fa-duotone fa-save"></i> Speichern</button>
      <button @click="deleteTaskType()" class="btn btn-outline-danger float-end me-3"><i class="fa-duotone fa-trash"></i> Löschen</button>
        </div>
      </div>
      <div class="row">

        <div class="col-12">

          <div class="form-group">

            <label>Titel</label>
            <input type="text" class="form-control" v-model="activeTaskType.name">

          </div>

        </div>

      </div>

      <div class="row">

        <div class="col-12">

          <label>Status</label>
          <select class="form-select" v-model="activeTaskType.status">

            <option value="open">Offen</option>

          </select>
          <label class="mt-2">Aufgaben-Typ</label>
          <select class="form-select" v-model="activeTaskType.type">
            <option value="upload">Datei-Upload</option>
            <option value="task">Aufgabe</option>
            <option value="choose">Kurs-Auswahl</option>
            <option value="optional">Optionaler Upload</option>
          </select>
          <label class="mt-2">Voraussetzungs-Typ</label>
          <select class="form-select" v-model="activeTaskType.requirement_type">
            <option value="basic">Allgemeine Zulassungsvoraussetzungen</option>
            <option value="special">Besondere Zulassungsvoraussetzungen</option>
          </select>

        </div>

      </div>
      <div class="row" v-if="activeTaskType.type === 'choose'">

          <div class="col-12">

            <label>Kurs-Auswahl {{activeTaskType.id}}</label>


              <template  :key="key" v-for="(choose,key) in activeTaskType.chooses" :value="choose.id">


                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                    <label>Titel</label>
                    <input type="text" class="form-control"  v-model="activeTaskType.chooses[key].title"  >
                  </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for="">Ausgebucht?</label>
                      <select style="min-height: 48px" class="form-control" v-model="activeTaskType.chooses[key].closed">
                        <option value="no">Nein</option>
                        <option value="hidden">Versteckt</option>
                        <option value="yes">Ja</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Beschreibung</label>
                    <textarea class="form-control" v-model="activeTaskType.chooses[key].description" cols="30" rows="10"></textarea>
                    </div>
                    </div>
                </div>





              </template>

            <button class="btn btn-success" @click="activeTaskType.chooses.push({id:activeTaskType.chooses.length, title:'', description: '', closed: 'no'})">+</button>


          </div>

      </div>

      <div class="row">

        <div class="class-12">

          <label class="mt-2">Beschreibung</label>

          <Editor2 v-model="activeTaskType.description" />

        </div>

      </div>
      <div class="row">

        <div class="class-12">

          <label class="mt-2">Öffentliche Beschreibung</label>

          <Editor2 v-model="activeTaskType.description_public" />

        </div>

      </div>



    </div>

  </div>


</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";


export default {
  name: "DFBTaskTypes",


  components: {Editor2},
  props: {
    requestId: {
      type: String,
      default: null
    },


  },


  methods: {

    deleteTaskType() {
      if (confirm("Aufgabe wirklich entfernen? Wird nur empfohlen, wenn noch keine Bewerbung gelaufen ist! Folge: Darstellung der Fragen nicht mehr möglich") === true) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "plugins/dfb/taskTypes/" + this.activeTaskType.id, {headers}).then(() => {
          this.getTaskTypes();
          this.activeTaskType = null;
        });
      }
    },

    getTaskTypes() {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/taskTypes?requestId="+this.requestId, {headers}).then((r) => {
        this.taskTypes = r.data.result.data;
        this.loading = false;
        this.activeTaskType = null;
      });

    },

    postOrPut() {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};


      if (!this.activeTaskType.id || this.activeTaskType.id === 'new') {
        this.activeTaskType.request_id = this.requestId;
        this.activeTaskType.id = null;
        axios.post(this.$store.state.apiUrl + "plugins/dfb/taskTypes", this.activeTaskType,{headers}).then(() => {

          this.loading = false;
          this.getTaskTypes();
          this.activeCalcType = null;


        });
      }
      else {
        axios.put(this.$store.state.apiUrl + "plugins/dfb/taskTypes", this.activeTaskType,{headers}).then(() => {

          this.loading = false;
          this.getTaskTypes();
          this.activeCalcType = null;
        });
      }
    },



  },

  data() {
    return {
      taskTypes: [],
      activeTaskType: {
        name: '',
        status: 'open',
        description_public: '',
      }

    };
  },

  created() {

    this.getTaskTypes();


  },

  watch: {
    requestId() {

      this.getTaskTypes();
    },
    activeTaskType() {
      if (this.activeTaskType) {
        if (!this.activeTaskType.chooses) {
          this.activeTaskType.chooses = [{id:0,title:'',description:''}];
        }
      }
    }
  }


};
</script>

<style lang="scss">

.product-item {
  cursor: pointer;
}

</style>
