<template>
  <div
    v-show="this.$store.state.layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-grey'}`"
  />
  <aside
    class="my-3 border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="`${
      this.$store.state.isRTL
        ? 'me-3 rotate-caret fixed-end'
        : 'fixed-start ms-3'
    }
    ${
      this.$store.state.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ' '
    } ${this.$store.state.sidebar ? 'closed' : ''}`"
    id="sidenav-main"
  >
    <div class="sidenav-header" v-if="!this.$store.state.sidebar">
      <div class="topMainIcons blue">
        <div class="actionIcon double center">
          <span class="innerFirst" :class="(filter === 'menu') ? 'active' : ''" @click="filter = 'menu'">
          <i class="fa-duotone fa-bars"></i>
          </span>
          <span class="innerLast" :class="(filter === 'filter') ? 'active' : ''" @click="filter = 'filter'">
            <i class="fa-duotone fa-filter"></i><template v-if="filter_count && filter_count > 0"><span
            class="indicator">{{ filter_count }}</span></template>
          </span>
        </div>
        <template
          v-if="$route && $route.matched && $route.matched[0] && $route.matched[0].name && $route.matched[0].name === 'customers' && !alreadySaved">
          <span class="filterSaveIndicator" tooltip="Filterauswahl speichern" position="bottom"
                @click="saveUserFilter($route.matched[0].name)"><i class="fa-duotone fa-floppy-disk"></i></span>
        </template>
        <div class="actionIcon single outter">
          <button @click="toggleSidebar" type="button" class="btn btn-toggler-sidebar"><i
            class="fa-duotone fa-chevron-left"></i></button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="actionIcon single outter">
        <button @click="toggleSidebar" type="button" class="btn btn-toggler-sidebar"><i
          class="fa-duotone fa-bars"></i><i class="fa-duotone fa-chevron-right"></i></button>
      </div>
    </div>
    <template v-if="filter ==='menu'">
      <sidenav-list :cardBg="custom_class"/>
    </template>
    <template v-else>
      <sidenav-filter :cardBg="custom_class"/>
    </template>
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import SidenavFilter from "./SidenavFilter.vue";
import logo from "@/assets/img/logo.png";
import logoWhite from "@/assets/img/logo-white.png";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {
    SidenavList, SidenavFilter
  },
  data() {
    return {
      logo,
      logoWhite,
      filter: "menu",
      sidebarToggle: false,
      alreadySaved: true,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    filter_count() {
      const routeName = this.$route.name;
      return this.$store.getters.getNumberOfActiveFilters(routeName);
    }
  },
  methods: {
    toggleSidebar() {
      this.filter = "menu";
      this.$store.commit("toggleSidebar");
    },
    saveUserFilter(filtertype) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "filters/" + filtertype, this.$store.state.filters[filtertype], {headers}).then((r) => {
        this.$store.dispatch("toastSuccess", 'Filter wurde gespeichert');
        this.getUser.employee.filter_customers = JSON.stringify(this.$store.state.filters.customers);
        this.compareFilters();
      });
    },
    compareFilters() {
      if (this.getUser && this.getUser.employee && this.getUser.employee.filter_customers && this.getUser.employee.filter_customers.length > 0) {
        const userFilter = JSON.parse(this.getUser.employee.filter_customers);
        const currentFilter = this.$store.state.filters.customers;
        // compare both filters and set alreadySaved to false if they are different
        this.alreadySaved = JSON.stringify(userFilter) === JSON.stringify(currentFilter);
      } else {
        this.alreadySaved = false;
      }
    },
    setFilterFromUserOnce() {
      if (this.getUser && this.getUser.employee && this.getUser.employee.filter_customers && this.getUser.employee.filter_customers.length > 0) {
        this.$store.state.filters.customers = JSON.parse(this.getUser.employee.filter_customers);
      }
    },
  },
  created() {
    this.setFilterFromUserOnce();
    this.compareFilters();
  },
  watch: {
    '$store.state.filters.customers': {
      handler() {
        this.compareFilters();
      },
      deep: true, // Add deep to watch nested properties within the filter
    },
  } ,
  props: ["custom_class", "layout"],
};
</script>
