<template>
  <div class="mailWrapper">
    <button class="btn btn-close float-end" @click="$store.commit('closeModal', 'mail')"></button>
    <template v-if="$route.name === 'customers' || $route.name === 'CustomersDetails'">
      <template v-if="customerSelected && customerSelected.length">{{ customerSelected.length }} Empfänger (Selektierte
        Auswahl)
      </template>
      <template v-else-if="customerActive">E-Mail geht an: {{ customerActive.c_email }}</template>
      <template v-else> {{ customerList.complete }} Empfänger (ganzer Filter)</template>
    </template>
    <template v-if="$route.name === 'employees'">
      <template v-if="employeeSelected && employeeSelected.length">{{ employeeSelected.length }} Empfänger (Selektierte
        Auswahl)
      </template>
      <template v-else>{{ getEmployees.length }} Emfänger (alle Mitarbeiter)</template>
    </template>
    <template v-if="$route.name === 'EmployeeDetails'">
      1 Empfänger (Selektierte Auswahl)
    </template>
    <hr>
    <div class="row">
      <div class="col-lg-9 col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <select style="width: 100%; margin-bottom: 24px;" v-model="activeDraft">
                <option :value="{draft: ''}">- Aus Vorlage wählen -</option>
                <optgroup :label="tit" v-for="(dr,tit) in activeDrafts">
                  <option :value="draft" v-for="draft in dr" :key="draft.id">{{ draft.title }}</option>
                </optgroup>
              </select>
            </div>
            <template v-if="activeDraft.attachmentTitle">
              <p class="alert alert-info">{{ activeDraft.attachmentTitle }} wird als Anhang mit geschickt!</p>
            </template>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <div class="row" v-if="activeMethod === 'attachment'">
              <div class="col-12">
                <div class="form-group">
                  <input type="text" class="form-control" :placeholder="$t('documents.emailSubject')"
                         v-model="mailer.separateSubject">
                </div>
                <div class="form-group">
                  <Editor2 v-model="mailer.separateContent"></Editor2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input type="text" class="form-control" :placeholder="$t('documents.emailPDFSubject')"
                         v-model="mailer.subject">
                </div>
                <div class="form-group">
                  <Editor2 v-model="mailer.content"></Editor2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input class="w-100 mb-2 mt-2" type="text" placeholder="BCC kommasepariert" v-model="mailer.bcc">
                <input type="file" style="width: 100%; margin-top: 24px;margin-bottom: 24px;" ref="file"
                       @change="readFile()">
              </div>
            </div>
          </div>
        </div>
        <div class="row"></div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <button type="button" class="btn btn-outline-primary w-100" @click="pdfOptions.bg  = true"
                        :class="(pdfOptions.bg ) ? 'btn-primary' : ''">
                  {{ $t("documents.withEmailTemplate") }}
                </button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-outline-primary w-100" @click="pdfOptions.bg  = false"
                        :class="(pdfOptions.bg) ? '' : 'btn-primary'">
                  {{ $t("documents.withoutEmailTemplate") }}
                </button>
              </div>
            </div>
            <div class="row" v-if="activeMethod !== 'mail'">
              <div class="col-6">
                <button type="button" class="btn btn-outline-primary w-100"
                        @click="pdfOptions.header = 'with'"
                        :class="(pdfOptions.header === 'with') ? 'btn-primary' : ''">
                  {{ $t("documents.withPDFHeader") }}
                </button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-outline-primary w-100"
                        @click="pdfOptions.header = 'without'"
                        :class="(pdfOptions.header === 'with') ? '' : 'btn-primary'">
                  {{ $t("documents.withoutPDFHeader") }}
                </button>
              </div>
            </div>
            <div class="row" v-if="activeMethod !== 'mail'">
              <div class="col-6">
                <button type="button" class="btn btn-outline-primary w-100" @click="pdfOptions.bg = true"
                        :class="(pdfOptions.bg === true) ? 'btn-primary' : ''">
                  {{ $t("documents.withPDFBackground") }}
                </button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-outline-primary w-100" @click="pdfOptions.bg = false"
                        :class="(pdfOptions.bg === true) ? '' : 'btn-primary'">
                  {{ $t("documents.withoutPDFBackground") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12"><p>{{ attachments.length }} Anhänge</p></div>
          <div class="col-6">
            <button type="button" class="btn btn-outline-secondary" @click="$store.commit('closeModal', 'mail')"><i
              class="fa-duotone fa-xmark-circle"></i> {{ $t("globals.cancel") }}
            </button>
          </div>
          <div class="col-6">
            <button type="button" @click="sendMail" class="btn btn-outline-primary float-end"><i
              class="fa-duotone fa-paper-plane"></i> {{ $t("globals.send") }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="draftVariables" v-if="draftVariables && draftVariables.length > 0">
          <p class="mb-1"><span style="font-weight: bold;" >Verfügbare Variablen</span> <span tooltip="Mit Klick kopieren"><i class="fa-duotone fa-info-circle"></i></span></p>
          <div class="overflowWrap" style="height:100%; overflow-y: auto; max-height: 80vh;">
          <ul class="list-group" style="margin-left: 0;">


          <template v-for="dvar in draftVariables">
            <li class="list-group-item " @click="insertText(dvar)"><span class="dvarName">{{dvar.name}}</span><span class="dvarValue" v-html="dvar.value"></span>
            </li>
          </template>
          </ul></div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";

export default {
  name: "Mail",
  components: {Editor2},
  props: {
    customer: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigs", "getPlugins", "customerActive", "draftList", "customerList", "customerSelected", "employeeSelected"]),
  },
  data() {
    return {
      activeMethod: 'mail',
      activeSubtab: null,
      activeDrafts: {},
      activeDraft: {draft: ''},
      withTemplate: false,
      attachments: [],
      filledVars: [],
      activeFile: null,
      file: null,
      activeFileName: null,
      orderTabs: [
        {name: 'base', label: this.$t('documents.baseSettings'), icon: 'fa-cog'},
        {name: 'booking', label: this.$t('documents.billingData'), icon: 'fa-coin'},
        {name: 'docs', label: this.$t('documents.documentDispatch'), icon: 'fa-envelope'},
      ],
      activeOrderTab: 'base',
      pdfOptions: {
        header: 'with',
        bg: true,
      },
      sent: null,
      sentError: null,
      showPdf: false,
      showOrder: false,
      showContract: false,
      mailer: {
        subject: '',
        separateSubject: '',
        content: '',
        separateContent: '',
      },
      drafts: [],
      draftVariables: []
    }
  },
  methods: {
    readFile() {
      let VueInner = this;
      let base64String = null;
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        this.file = this.$refs.file.files[i];
        this.$log.debug("reading");
        this.$log.debug(this.$refs);
        this.activeFileName = this.file.name;
        if (this.file) {
          var reader = new FileReader();
          reader.onloadend = () => {
            // Use a regex to remove data url part
            base64String = reader.result
              .replace('data:', '')
              .replace(/^.+,/, '');
            VueInner.attachments.push({name: VueInner.activeFileName, data: base64String});
          };
          reader.readAsDataURL(this.file);
        }
      }
    },
    getDrafts() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search?limit=0", {headers}).then((r) => {
        this.drafts = r.data.result.data;
        // sort drafts by group_id in activeDrafts
        this.drafts.forEach((draft) => {
          if (this.activeDrafts[draft.categoryTitle] === undefined) {
            this.activeDrafts[draft.categoryTitle] = [];
          }
          this.activeDrafts[draft.categoryTitle].push(draft);
        });
        this.loading = false;
      });
    },
    insertText(dvar) {
    this.$store.dispatch('copyToClipboard', dvar.name);

    },
    getDraftVariables() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/variables", {headers}).then((r) => {
        this.draftVariables = r.data.result.data;
      });
    },
    setDraft() {
      this.mailer.content = this.activeDraft.draft;
      this.mailer.separateContent = this.activeDraft.draft;
      if (this.activeDraft.attachment_id) {
        this.attachments.push(this.activeDraft.attachment_id);
      }
    },
    sendMail() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let url = "";
      let data = {};
      let selected;
      if (this.$route.name === 'employees') {
        data = {
          employees: this.employeeSelected,
          mailer: this.mailer,
          pdfOptions: this.pdfOptions,
          attachments: this.attachments,
          withTemplate: this.withTemplate,
          filledVars: this.filledVars,
          attachment: this.activeFile,
          filename: this.activeFileName,
        };
        if (this.employeeSelected && this.employeeSelected.length) {
          selected = this.employeeSelected;
        } else if (this.$route.params.id) {
          let key = this.$route.params.id;
          selected = {[key]: true};
        } else {
          selected = null;
        }
        url = "batch/employees/mail";
      }
      if (this.$route.name === 'EmployeeDetails') {
        data = {
          employee: this.$route.params.id,
          mailer: this.mailer,
          pdfOptions: this.pdfOptions,
          attachments: this.attachments,
          withTemplate: this.withTemplate,
          filledVars: this.filledVars,
          attachment: this.activeFile,
          filename: this.activeFileName,
        };
        const list = this.$store.state.lists.employees;
        if (this.employeeSelected && this.employeeSelected.length) {
          selected = list.selected;
        } else if (this.$route.params.id) {
          let key = this.$route.params.id;
          selected = {[key]: true};
        } else {
          selected = null;
        }
        url = "batch/employees/mail";
      }
      if (this.$route.name === 'customers' || this.$route.name === 'CustomersDetails' || this.$route.name === 'CustomersTabs' || this.$route.name === 'CustomersTabsSubtabs') {
        data = {
          mailer: this.mailer,
          pdfOptions: this.pdfOptions,
          attachments: this.attachments,
          withTemplate: this.withTemplate,
          filledVars: this.filledVars,
          attachment: this.activeFile,
          filename: this.activeFileName,
        };
        const list = this.$store.state.lists.customers;
        if (this.customerSelected && this.customerSelected.length) {
          selected = list.selected;
        } else if (this.customerActive) {
          let key = this.customerActive.id;
          selected = {[key]: true};
        } else {
          selected = null;
        }
        url = "batch/customers/mail";
      }
      const filters = this.$store.state.filters.customers;
      axios.post(this.$store.getters.getApiUrl + url, {
        "selected": selected,
        "filters": filters,
        "data": data
      }, {headers}).then((r) => {
        this.sent = r.data.result;
        this.sentError = null;
        this.showPdf = false;
        this.showOrder = false;
        this.showContract = false;
        this.mailer = {
          subject: '',
          separateSubject: '',
          content: '',
          separateContent: '',
        };
        this.attachments = [];
        this.filledVars = [];
        this.$store.dispatch("toastSuccess", "Emails wurden generiert");
        this.$store.commit('closeModal', 'mail');
      });
    },
  },
  created() {
    this.getDraftVariables();
    this.getDrafts();
    if (this.$route.name === 'EmployeeDetails') {
      const id = this.$route.params.id;
    }
  },
  watch: {
    activeDraft() {
      this.attachments = [];
      if (this.activeDraft) {
        if (this.activeMethod === 'attachment') {
          this.mailer.separateContent = this.activeDraft.draft;
          this.mailer.separateSubject = this.activeDraft.title;
        } else {
          this.mailer.content = this.activeDraft.draft;
          this.mailer.subject = this.activeDraft.title;
          if (this.activeDraft.attachment_id) {
            this.attachments.push(this.activeDraft.attachment_id);
          }
        }
      }
    },
  }
}
</script>
<style>
.mailWrapper {
  padding: 20px;
  background: #fff;
}
</style>
