<template>
  <div class="card-body">
    <button type="button" class="btn btn-outline-primary btn-sm float-end" @click="setPassword()"
    >
      <i class="fa-duotone fa-pen-field"></i> {{ $t("globals.newInfo") }}
    </button>
    <div style="max-height: 365px; clear: both; width: 100%;overflow-y: scroll;overflow-x: hidden;">
      <div class="documentWrap passwordInfoWrap">
        <template v-for="info in infos">
          <div class="document" :class="(info.id === selectedRow) ? 'active' : '' ">
            <div class="lineitem l-action">
              <div class="btn-group">
                <button type="button" class="btn-outline-primary btn btn-sm" @click="setPassword(info)"
                        :tooltip="this.$t('passwords.editPassword')" :position="'right'"><i
                  class="fa-duotone fa-edit"></i></button>
                <button type="button" class="btn-outline-danger btn btn-sm" @click="deletePassword(info.id)"
                        :tooltip="this.$t('passwords.deletePassword')" :position="'right'"><i
                  class="fa-duotone fa-trash"></i></button>
              </div>
            </div>

            <template v-if="info.id === selectedRow">
              <div class="lineitem l-title" @click="selectRow(null)">{{ info.a_title }} <small v-if="!info.a_title">{{ info.url }}</small></div>
            </template>
            <template v-else>
              <div class="lineitem l-title" @click="selectRow(info.id)">{{ info.a_title }} <small v-if="!info.a_title">{{ info.url }}</small></div>
            </template>

            <div class="lineitem l-type"><span class="badge text-xs">{{ info.type }}</span></div>
            <div class="lineitem  last ml-auto ">
              <template v-if="info.id === selectedRow"><i class="fa-duotone fa-chevron-up" @click="selectRow(null)"></i>
              </template>
              <template v-else><i class="fa-duotone fa-chevron-down" @click="selectRow(info.id)"></i></template>

            </div>

          </div>
          <div class="infoContent" v-if="info.id === selectedRow">
            <div class="row">
              <div class="col-xl-12 col-12">
                <div class="card">
                  <div class="card-body">
                    <div style="max-height:440px;overflow-y: scroll;overflow-x: hidden;">
                      <button class="copyClip" v-if="info.user" @click="$store.dispatch('copyToClipboard',info.user)"><i
                        class="fa-duotone fa-user"></i> {{ info.user }}
                      </button>
                      <button class="copyClip"  v-if="info.dbName" @click="$store.dispatch('copyToClipboard',info.dbName)"><i
                        class="fa-duotone fa-database"></i> {{ info.dbName }}
                      </button>
                      <button class="copyClip" v-if="info.password"
                              @click="$store.dispatch('copyToClipboard',info.password)"><i
                        class="fa-duotone fa-key"></i> {{ info.password }}
                      </button>
                      <div class="copyAndExternal" v-if="info.url">
                        <div class="btn-group">
                          <button class="copyClip" v-if="info.url" @click="$store.dispatch('copyToClipboard',info.url)">
                            <i
                              class="fa-duotone fa-copy"></i>
                          {{ info.url }}<template v-if="info.port && info.port !== '0'">:{{info.port}}</template>
                          </button><a :href="info.url" target="_blank" class="text-sm d-block" v-if="info.url" tooltip="Link öffnen" position="right"><i
                            class="fa-duotone fa-square-arrow-up-right"></i></a>
                        </div>
                      </div>
                      <button class="copyClip" @click="$store.dispatch('copyToClipboard',info.mail)" v-if="info.mail"><i
                        class="fa-duotone fa-envelope"></i>
                        {{ info.mail }}
                      </button>
                      <p v-html="info.custom_info" class="preInfoContent"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <Modal v-if="activePassword">
    <div class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ $t("views.signIn.newPassword") }}</h5>
            <button type="button" class="btn-close" @click="resetPassword"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <select class="form-select" v-model="activePassword.type">
                <option value="">{{ $t("globals.plsChoose") }}</option>
                <template v-for="type in passwordData.types">
                  <option :value="type.type">{{ type.name }}</option>
                </template>
              </select>
            </div>
            <template v-if="activePassword.type">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ $t("globals.designation") }}</label>
                    <input class="form-control" type="text" v-model="activePassword.a_title"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ $t("passwords.webAddressServerHost") }}</label>
                    <input class="form-control" type="text" v-model="activePassword.url"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ $t("globals.user") }}</label>
                    <input class="form-control" type="text" v-model="activePassword.user"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ $t("views.signIn.password") }}</label>
                    <input class="form-control" type="text" v-model="activePassword.password"/>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="activePassword.type === 'email'">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ $t("globals.emailAddress") }}</label>
                    <input type="email" class="form-control" v-model="activePassword.mail"></div>
                </div>
              </div>
            </template>
            <template v-if="activePassword.type === 'ftp' || activePassword.type === 'email'">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ $t("globals.port") }}</label>
                    <input type="number" step="1" min="0" class="form-control" v-model="activePassword.port"></div>
                </div>
              </div>
            </template>
            <template v-if="activePassword.type === 'db'">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ $t("passwords.databaseName") }}</label>
                    <input type="text" class="form-control" v-model="activePassword.dbName"></div>
                </div>
              </div>
            </template>
            <template v-if="activePassword.type">
              <Editor2 v-model="activePassword.custom_info"/>
            </template>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-6">
                <button type="button" class="btn btn-danger" @click="resetPassword()">
                  {{ $t("globals.cancel") }}
                </button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-success float-end" @click="editPassword()"
                        v-if="activePassword.id">
                  {{ $t("globals.save") }}
                </button>
                <button type="button" class="btn btn-success float-end" @click="postPassword()" v-else>
                  {{ $t("globals.save") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";

export default {
  name: "CustomerPasswords",
  components: {Editor2},
  data() {
    return {
      infos: [],
      activePassword: null,
      selectedRow: null,
      passwordData: {
        projects: [],
        types: [
          {type: "backend", name: this.$t("passwords.types.backend")},
          {type: "ftp", name: this.$t("passwords.types.ftp")},
          {type: "email", name: this.$t("passwords.types.email")},
          {type: "db", name: this.$t("passwords.types.database")},
          {type: "provider", name: this.$t("passwords.types.provider")},
          {type: "htaccess", name: this.$t("passwords.types.htaccess")},
          {type: "teamviewer", name: this.$t("passwords.types.teamviewer")},
          {type: "paypal", name: this.$t("passwords.types.paypal")},
          {type: "other", name: this.$t("passwords.types.other")}],
      }
    };
  },
  computed: {
    ...mapGetters(["customerActive"]),
  },
  emits: ['refresh-passwords'],
  methods: {
    getCustomerInformation() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/accessData", {headers}).then((r) => {
        this.infos = r.data.result.data;
      });
    },
    selectRow(id) {
      this.selectedRow = id;
    },
    setPassword(password) {
      if (password) {
        this.activePassword = password;
      } else {
        this.activePassword = {
          type: "",
          user: "",
          password: "",
          custom_info: "",
          url: "",
          port: "",
          mail: "",
        };
      }
    },
    resetPassword() {
      this.activePassword = false;
    },
    deletePassword(id) {
      this.$confirm(this.$t('passwords.confirmTextDeletePassword'), this.$t('passwords.deletePassword'), {
        confirmButtonText: this.$t('globlas.yes'),
        cancelButtonText: this.$t('globlas.no'),
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "accessData/" + id, {headers}).then((r) => {
          this.$store.dispatch("toastSuccess", this.$t("toast.success.passwordDeleted"));
          this.activePassword = false;
          this.loading = false;
          this.infos = r.data.result.data;
          this.$emit('refresh-passwords');
        });
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.loading = false;
      });
    },
    editPassword() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let editedPassword = {
        hash: this.customerActive.hash,
        data: this.activePassword
      }
      axios.put(this.$store.state.apiUrl + "accessData/" + this.activePassword.id, editedPassword, {headers}).then((r) => {
        this.$store.dispatch("toastSuccess", this.$t("toast.success.passwordSaved"));
        this.infos = r.data.result.data;
        this.loading = false;
        this.activePassword = false;
        this.$emit('refresh-passwords');
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.loading = false;
      });
    },
    postPassword() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        hash: this.customerActive.hash,
        data: this.activePassword
      }
      axios.post(this.$store.state.apiUrl + "accessData/", postContent, {headers}).then((r) => {
        this.$store.dispatch("toastSuccess", this.$t("toast.success.passwordCreated"));
        this.infos = r.data.result.data;
        this.loading = false;
        this.activePassword = null;
        this.$emit('refresh-passwords');
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.loading = false;
      });
    },
  },
  created() {
    this.getCustomerInformation();
  }
}
</script>

