<template>
  <div class="customerLineWrap"
       :class="(customer.prepared && customer.prepared.typeString) ? customer.prepared.typeString : ''">
    <div class="lineitem first l-checkbox">
      <div class="checkbox"><input type="checkbox" :id="'id_'+customer.id" :value="customer.id"
                                   v-if="customerList && customerList.selected"
                                   v-model="customerList.selected[customer.id]"/><label
        :for="'id_'+customer.id"></label></div>
    </div>
    <div class="lineitem l-company" @click="setCustomer(customer)">

      <template v-if="customer.c_company">
        {{ customer.c_company }}
        <span class="text-xs customerInfo" v-if="customer.c_title || customer.c_firstname || customer.c_lastname">
          <template v-if="customer.c_title">{{ customer.c_title }}&nbsp;</template>
          <template v-if="customer.c_firstname">{{ customer.c_firstname }}&nbsp;</template>
          <template v-if="customer.c_lastname">{{ customer.c_lastname }}</template>
        </span>
      </template>
      <template v-else-if="customer.c_title || customer.c_firstname || customer.c_lastname">
        <template v-if="customer.c_title">{{ customer.c_title }}&nbsp;</template>
        <template v-if="customer.c_firstname">{{ customer.c_firstname }}&nbsp;</template>
        <template v-if="customer.c_lastname">{{ customer.c_lastname }}</template>
      </template>
      <template v-else>
        {{ customer.c_email }}
      </template>
    </div>
    <div class="lineitem l-number" @click="setCustomer(customer)"
         v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_customernr_listing === '1'">
      <template v-if="customer.c_customer_id && customer.c_customer_id !== '0'">
        <strong>{{ customer.c_customer_id }}</strong></template>
      <template v-else>{{
          customer.category
        }}
      </template>
    </div>
    <div class="lineitem l-prio"
         v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_prio_listing === '1'"><span
      class="roundBadge" :style="{backgroundColor: customer._prio_color}">{{
        customer._prio_name
      }}</span>
    </div>
    <div class="lineitem l-akquise" :tooltip="customer._akquise_name" :position="'left'"
         v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_akquise_listing === '1'"
         :style="{ color: customer._akquise_color}"><i class="fa-duotone" :class="'fa-'+customer._akquise_icon"></i>
    </div>
    <div class="lineitem l-type" :style="{color: customer._type_color}" :tooltip="customer._type_name"
         :position="'left'"
         v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_custype_listing === '1'">
      <template v-if="customer._type_icon">
      <i
      :class="'fa-'+customer._type_icon" class="fa-duotone"></i></template>
      <template v-else>
        <small style="max-width: 100%;text-align: center; white-space: nowrap; font-size: 10px;text-overflow: ellipsis; overflow: hidden;">{{customer._type_name }}</small>
      </template>
    </div>
    <div class="lineitem l-birthday"
         v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_birthday_listing === '1'">
      <span position="left" tooltip="Geburstag" v-if="customer.c_birthday !== '0000-00-00'"><i
        class="fa-duotone fa-cake-candles"></i> {{ $filters.formatDate(customer.c_birthday) }}</span>
    </div>
    <div class="lineitem l-verband license" v-if="getPlugins.verband">
      <template v-if='customer.customFields && customer.customFields[1].fields["trainer-lizenz"].formattedValue'>
        {{ customer.customFields[1].fields["trainer-lizenz"].formattedValue }}
      </template>
      <template v-else>keine</template>
    </div>
    <div class="lineitem l-verband" v-if="getPlugins.verband">
      <template v-if='customer.le'>{{ customer.le.pointsInCurrentPeriod }} LE</template>
      <template v-else>0 LE</template>
    </div>
    <Holzkraft :project="customer" mode="listHeadline"
               v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft"/>

    <Dfb :customer="customer" mode="listHeadline" v-if="getPlugins && getPlugins.infos  && getPlugins.infos.trainer" />
    <div class="lineitem l-country" :tooltip="customer._countryName" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_country_listing === '1'">
      <span >{{customer._countryName}}</span>

    </div>
    <div class="lineitem l-createdon"
         v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_createdon_listing === '1'"><span
      position="left"
      tooltip="Erstellungdatum"><i
      class="fa-duotone fa-calendar-plus"></i> {{ $filters.formatDate(customer.ts_created) }}</span>
    </div>
    <div class="lineitem l-last-activity">
      <template v-if="customer.c_last_activity && customer.c_last_activity !== '0000-00-00 00:00:00' && getConfigs && getConfigs.params_values && getConfigs.params_values.use_last_activity_listing === '1' ">
        <span position="left" tooltip="Letzte Aktivität">{{
          $filters.formatDateTime(customer.c_last_activity)
        }}</span>
      </template>
    </div>
    <div class="lineitem actions">
      <button :tooltip='$t("globals.order")' position="left" class="iconAction btn btn-sm btn-outline-primary"
              @click='docSetCustomer(customer);'><i
        class="fa-duotone fa-file-contract"></i></button>
    </div>
    <div class="lineitem actions">
      <button :tooltip='$t("globals.email")' position="left" class="iconAction btn btn-sm btn-outline-primary"
              @click='setCustomer(customer); $store.commit("openModal", "mail");'><i
        class="fa-duotone fa-mail-bulk"></i></button>
    </div>
    <div class="lineitem actions" v-if="getMenu && getMenu.tasks">
      <button :tooltip="$t('tasks.create.task')" position="left"
              class="iconAction btn btn-sm btn-outline-primary btn-purple"
              @click=" showCreateTask = true;setCustomer(customer)"><i data-v-0bc16d12=""
                                                                       class="fa-duotone fa-clipboard-list-check"></i></button>
    </div>
    <div class="lineitem actions" v-if="getMenu && getMenu.accounting">

      <template v-if="!customer._hasActiveSepa">
      <button :tooltip="$t('add.sepa')" position="left"
              class="iconAction btn btn-sm btn-outline-danger btn-red"
              @click=" showCreateSepa = true;"><i data-v-0bc16d12=""
                                                                       class="fa-duotone fa-wallet"></i></button></template>
    </div>
    <div class="lineitem l-avatar ml-auto"
         v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_employeeicon_listing === '1'"><img
      :src="$filters.employeeIcon(getEmployees, customer.c_owner)"
      class="sketchAvatar"></div>
    <div class="lineitem last"
         :class="(getConfigs && getConfigs.params_values && getConfigs.params_values.use_employeeicon_listing === '1') ? 'ms-3' : 'ml-auto'">
      <template v-if="customerActive && customerActive.id === customer.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeCustomer')"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up" @click="setCustomer(customer)"></i></template>
    </div>
  </div>
  <Modal v-if="modalsList.document && customerActive.id === customer.id ">
    <div class="modal fade show" id="modalOrder" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body">
            <Edit :document="{}" context-id="modal" :modal="true"/>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="showCreateTask ">
    <div class="modal fade show" id="modalOrder" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <CreateTask :taskActive="{}" comefrom="customer" @closeTask="showCreateTask=false;" :projectsProp="false"/>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="showCreateSepa">
    <div class="modal fade show" id="modalOrder" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body" style="padding: 0;">
            <Sepadetails :sepa="{}" :customer="customer" @closeSepa="showCreateSepa=false;"/>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import Edit from "@/routing/core/Documents/Edit";
import $ from "jquery";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";
import CreateTask from "@/routing/core/Tasks/Create.vue";
import Sepadetails from "@/routing/core/Sepa/Details";
import Dfb from "@/routing/plugins/dfb/Customer.vue";
export default {
  name: "Headline",
  data() {
    return {
      modalContent: null,
      showCreateTask: false,
      showCreateSepa: false,
      toast: useToast(),
      address: {},
      notice: {
        customer_id: null,
        notice_message: ''
      }
    }
  },
  props: {
    customer: Object,
  },
  components: {
    CreateTask,
    Holzkraft,
    Edit,Dfb,
    Sepadetails
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "getConfigs", "customerList", "getPlugins", "modalsList", "getMenu"])
  },
  methods: {
    docSetCustomer(customer) {
      let VueInner = this;
      this.$store.dispatch("setActiveCustomer", customer.id).then(function () {
        VueInner.scrollToTop();
        VueInner.$store.commit("openModal", "document");
      });
    },
    setCustomer(customer) {
      let VueInner = this;
      this.activeCustomer = customer.id;
      this.$store.dispatch("setActiveCustomer", customer.id).then(function () {
        VueInner.$router.push({name: "CustomersDetails", params: {id: customer.id}});
        VueInner.scrollToTop();
      });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
}
</script>
